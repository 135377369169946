import styled from 'styled-components'

export const HeaderWraper = styled.div`
  position: relative;
  background: #000;
  color: white;
  font-size: 16px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a {
    display: flex;
  }
  img {
    max-height: 45px;
    @media (max-width: 576px) {
      max-height: 30px;
    }
  }
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  > a {
    color: white;
    margin-left: 20px;
    text-decoration: none;
    &:hover {
      color: #dbdbdb;
    }
  }
  button:hover {
    color: #dbdbdb;
    border-color: #dbdbdb;
  }
`