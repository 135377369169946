import React from 'react'
import { Profil as ProfilController } from '../../../components/Account/Profil'

export const Profil = () => {
  return (
    <>
      <ProfilController />
    </>
  )
}
