import React, { useState } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { PageNotFound } from '../../pages/PageNotFound'
import { useSelector } from 'react-redux'
import { Protected } from '../../components/Admin/Shared/Protected'
import { useWindowSize } from '../../hooks/useWindowSize'
import { Sidebar } from '../../components/Admin/Sidebar'
import { FiArrowRight } from 'react-icons/fi'
import { Login } from './Login'
import { Home } from './Home'
import { TemplateCreate } from './TemplateCreate'
import { Category } from './Category'
import { CategoryCreate } from './CategoryCreate'
import { Users } from './Users'

import { Container, ContentContainer } from './styles'
import { ContactList } from './ContactList'
import { ContactInfo } from './ContactInfo'

export const AdminRouter = () => {
  const location = useLocation()
  const path = location.pathname
  const adminStore = useSelector((store) => store.adminStore)
  const { width } = useWindowSize()
  const [isMobile, setIsMobile] = useState(false)

  return (
    <Container>
      {!path.includes('/admin/login') && (
        <>
          <Sidebar setIsMobile={setIsMobile} isMobile={isMobile} />
          {width < 576 && (
            <FiArrowRight onClick={() => setIsMobile(true)} />
          )}
        </>
      )}
      <ContentContainer>
        <Routes>
          <Route
            path="/"
            element={
              <Protected>
                <Home />
              </Protected>
            }
          />
          <Route
            path="/temp_create"
            element={
              <Protected>
                <TemplateCreate />
              </Protected>
            }
          />
          <Route
            path="/category"
            element={
              <Protected>
                <Category />
              </Protected>
            }
          />
          <Route
            path="/cat_create"
            element={
              <Protected>
                <CategoryCreate />
              </Protected>
            }
          />
          <Route
            path="/users"
            element={
              <Protected>
                <Users />
              </Protected>
            }
          />
          <Route
            path="/contact_list"
            element={
              <Protected>
                <ContactList />
              </Protected>
            }
          />
          <Route
            path="/contact_info"
            element={
              <Protected>
                <ContactInfo />
              </Protected>
            }
          />
          <Route
            path="/login"
            element={
              adminStore?.admin?.token ? (
                <Navigate to="/admin/" />
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="*"
            element={
              <Protected>
                <PageNotFound />
              </Protected>
            }
          />
        </Routes>
      </ContentContainer>
    </Container>
  )
}
