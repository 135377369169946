import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  >svg{
    position: absolute;
    font-size: 20px;
    left: 17px;
    top: 22px;
  }
`

export const ContentContainer = styled.div`
  flex: 1;
  background-color: #fff6f6;
  height: 100vh;
  width: 100%;
`