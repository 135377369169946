import React from 'react';
import { FaUserEdit } from 'react-icons/fa';

import {
  Container,
  ImageWraper,
  UserInfoContainer
} from './styles'

export const TierItem = (props) => {
  const { item } = props

  return (
    <Container>
      <ImageWraper>
        <img src={item?.imageUrl} alt="Tier" />
      </ImageWraper>
      <UserInfoContainer>
        <FaUserEdit />
        {item?.users[0]?.name}
      </UserInfoContainer>
    </Container>
  )
}