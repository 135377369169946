import styled from 'styled-components'

export const Container = styled.div`
  // margin: 80px 0px;
`

export const FormWraper = styled.form`
  text-align: center;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const FormLabel = styled.label`
  text-align: right;
  padding: 0px 10px;
  width: 30%;
  padding-top: 8px;

  @media (max-width: 576px) {
    text-align: left;
    width: 100%;
  }
`

export const FormInput = styled.div`
  width: 40%;
  text-align: left;

  @media (max-width: 576px) {
    width: 100%;
  }
`

export const ErrorContainer = styled.div`
  color: red;
  font-size: 14px;
`

export const ButtonWraper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`
