import { configureStore } from '@reduxjs/toolkit'

import authReducer from './auth'
import adminReducer from './admin'
import settingReducer from './setting'

const store = configureStore({
  reducer: {
    authStore: authReducer,
    adminStore: adminReducer,
    settingStore: settingReducer
  },
})

export default store
