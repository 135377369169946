import React from 'react'
import { Category as CategoryCreateController } from '../../../components/Admin/Category'

export const Category = () => {
  return (
    <>
      <CategoryCreateController />
    </>
  )
}
