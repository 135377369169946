import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  Overlay,
  Aside,
  LinkContainer,
} from './styles'

export const MoblilAside = (props) => {
  const { isMenu, setIsMenu, logout } = props
  const authStore = useSelector((store) => store.authStore)

  return (
    <>
      <Overlay active={isMenu} onClick={() => setIsMenu(false)} />
      <Aside active={isMenu}>
        <AiOutlineClose size="23" onClick={() => setIsMenu(false)} />
        <LinkContainer onClick={() => setIsMenu(false)}>
          <Link to="/template/create">Make a Template</Link>
          <Link to="/categories">Categories</Link>
          {authStore.auth?.token ? (
            <>
              <Link to="/account/profil">
                Profil
              </Link>
              {/* <Link to="/account/your_categories">
                Your Categories
              </Link>
              <Link to="/account/your_templates">
                Your Templates
              </Link>
              <Link to="/account/your_templates">
                Your Tiers
              </Link> */}
              <Link to="/" onClick={logout}>
                LogOut
              </Link>
            </>
          ) : (
            <>
              <Link to="/login">Login</Link>
              <Link to="/register">Register</Link>
            </>
          )}
        </LinkContainer>
      </Aside>
    </>
  )
}