import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import settingService from '../service/settingService';

export const fetchAll = createAsyncThunk(
  'fetchAll',
  async () => {
    return await settingService.fetchAll();
  },
)

export const update = createAsyncThunk(
  'update',
  async (data) => {
    return await settingService.update(data);
  },
)


export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    data: {},
    loading: false,
  },

  extraReducers: (builder) => {
    // ============ fetch all =================================
    builder.addCase(fetchAll.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })

    // ============ update =================================
    builder.addCase(update.pending, (state) => {
      state.loading = true
    })
    builder.addCase(update.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
  },
})

export default settingSlice.reducer