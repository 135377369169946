import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../../Shared/Inputs';
import { Button } from '../../../Shared/Buttons';
import { useSelector } from 'react-redux';
import authService from '../../../../service/authService';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  FormWraper,
  FormRow,
  FormLabel,
  FormInput,
  ErrorContainer,
  ButtonWraper
} from './styles'

export const PasswordChange = () => {
  const authStore = useSelector((store) => store.authStore)
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    data.id = authStore?.auth?.user?._id;
    const res = await authService.changePassword(data, authStore?.auth?.token)
    if (!res?.status) {
      toast.error(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.success(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    setLoading(false);
  }

  return (
    <Container>
      <h4>Your Password Change</h4>
      <FormWraper onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormLabel>Password: </FormLabel>
          <FormInput>
            <Input
              type="password"
              {...register('password', {
                required: 'Password is required',
              })}
            />
            <ErrorContainer>
              {errors?.old_password?.message}
            </ErrorContainer>
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>New Password: </FormLabel>
          <FormInput>
            <Input
              type="password"
              {...register('new_password', {
                required: 'New Password is required',
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters"
                }
              })}
            />
            <ErrorContainer>
              {errors?.new_password?.message}
            </ErrorContainer>
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>Confirm Password: </FormLabel>
          <FormInput>
            <Input
              type="password"
              {...register('conf_password', {
                required: 'Confirm Password is required',
                validate: value =>
                  value === getValues('new_password') || 'Password is not matched with new password'
              })}
            />
            <ErrorContainer>
              {errors?.conf_password?.message}
            </ErrorContainer>
          </FormInput>
        </FormRow>
        <ButtonWraper>
          <Button
            color="primary"
            type="submit"
            disabled={loading}
            isLoading={loading}
          >
            Change Password
          </Button>
        </ButtonWraper>
      </FormWraper>
      <ToastContainer />
    </Container>
  )
}