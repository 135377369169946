import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Title
} from './styles'

export const CategoryItem = (props) => {
  const { item } = props

  return (
    <Container>
      <Link
        to={`/category/${item.name
          .toLowerCase()
          .replace(' ', '-')}`}
        key={item._id}
      >
        <img
          src={
            process.env.REACT_APP_BACKEND + '/' + item.image
          }
          alt={item.name}
        />
        <Title>{item.name}</Title>
      </Link>
    </Container>
  )
}