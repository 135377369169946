import React from 'react'
import { List } from '../../../components/Category/List'

export const CategoryList = (props) => {
  return (
    <>
      <List {...props} />
    </>
  )
}
