import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LayoutContainer } from '../Shared/LayoutContainer'
import { Input } from '../Shared/Inputs'
import { Button } from '../Shared/Buttons'
import axios from 'axios'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Container,
  FormWraper,
  FormRow,
  FormLabel,
  FormInput,
  ErrorContainer,
  ButtonWraper,
  CaptchaContainer,
  CaptchaContent,
} from './styles'

export const Register = () => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [capCode, setCapCode] = useState(null)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required.')
      .email('Email is wrong'),
    password: yup
      .string()
      .required('Password is required.')
      .test('xx2', 'must include number', (value) =>
        /[0-9]/.test(value),
      )
      .test('xx2', 'must include Uppercase', (value) =>
        /[A-Z]/.test(value),
      )
      .test('xx2', 'must include Lowercase', (value) =>
        /[a-z]/.test(value),
      )
      .test(
        'xx2',
        'must include Special character',
        (value) => /[!@#$%^*]/.test(value),
      )
      .min(8, 'Password need to has 8 signs.'),
    conf_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    capInput: yup
      .string()
      .test(
        'xxx',
        'Capture code is required and match',
        function (value) {
          return capCode === value
        },
      ),
  })

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!result?.status) {
      toast.error(result?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.success(result?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [result])

  const onSubmit = async (data) => {
    setLoading(true)
    data.role = 'user'
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/signup',
      data,
    )
    setResult(response.data)
    setLoading(false)
  }

  const genCap = () => {
    const letters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const a = letters[Math.floor(Math.random() * letters.length)]
    const b = letters[Math.floor(Math.random() * letters.length)]
    const c = letters[Math.floor(Math.random() * letters.length)]
    const d = letters[Math.floor(Math.random() * letters.length)]
    const code = a + b + c + d
    setCapCode(code)
  }

  useEffect(() => {
    genCap()
  }, [])

  return (
    <Container>
      <LayoutContainer>
        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <h2>Register your account!</h2>
          <FormRow>
            <FormLabel>Your Name :</FormLabel>
            <FormInput>
              <Input
                type="text"
                {...register('name', { required: 'Name is required' })}
              />
              <ErrorContainer>{errors?.name?.message}</ErrorContainer>
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>Email Address :</FormLabel>
            <FormInput>
              <Input type="email" {...register('email')} />
              <ErrorContainer>{errors?.email?.message}</ErrorContainer>
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>Password :</FormLabel>
            <FormInput>
              <Input type="password" {...register('password')} />
              <ErrorContainer>
                {errors?.password?.message}
              </ErrorContainer>
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>Confirm Password :</FormLabel>
            <FormInput>
              <Input type="password" {...register('conf_password')} />
              <ErrorContainer>
                {errors?.conf_password?.message}
              </ErrorContainer>
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel></FormLabel>
            <FormInput>
              <CaptchaContainer>
                <CaptchaContent
                  bgImage={theme?.images?.captchaBg}
                  onClick={genCap}
                >
                  {capCode}
                </CaptchaContent>
                <Input
                  type="text"
                  placeholder="0000"
                  maxLength={4}
                  {...register('capInput')}
                />
              </CaptchaContainer>
              <ErrorContainer>
                {errors?.capInput?.message}
              </ErrorContainer>
            </FormInput>
          </FormRow>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={loading}
              isLoading={loading}
            >
              Register
            </Button>
          </ButtonWraper>
        </FormWraper>
        <ToastContainer />
      </LayoutContainer>
    </Container>
  )
}
