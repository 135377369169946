import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { FiMenu } from 'react-icons/fi'
import { LayoutContainer } from '../Shared/LayoutContainer'
import { Button, OutlineBtn } from '../Shared/Buttons'
import { signout } from '../../store/auth'
import { useWindowSize } from '../../hooks/useWindowSize'
import { MoblilAside } from './MoblilAside'
import { AccountDropDown } from './AccountDropDown'

import {
  HeaderWraper,
  Container,
  HeaderActions
} from './styles'

export const Header = () => {
  const theme = useTheme()
  const { width } = useWindowSize()
  const [isMenu, setIsMenu] = useState(false)
  const dispatch = useDispatch()
  const authStore = useSelector((store) => store.authStore)
  const setting = useSelector((store) => store.settingStore?.data?.setting)

  const logout = () => {
    dispatch(signout())
  }

  return (
    <HeaderWraper>
      <LayoutContainer hasFooter={false}>
        <Container>
          <Link to="/">
            <img src={setting?.logo || theme.images.siteLogo} alt="Logo" />
          </Link>
          {width > 576 ? (
            <HeaderActions>
              <Link to="/template/create">
                <Button color="primary" size="small">
                  Make a Template
                </Button>
              </Link>
              <Link to="/categories">Categories</Link>
              {authStore.auth?.token ? (
                <AccountDropDown logout={logout} />
              ) : (
                <>
                  <Link to="/login">
                    <OutlineBtn size="small" color="white">
                      Login
                    </OutlineBtn>
                  </Link>
                  <Link to="/register">
                    <OutlineBtn size="small" color="white">
                      Register
                    </OutlineBtn>
                  </Link>
                </>
              )}
            </HeaderActions>
          ) : (
            <FiMenu size="23" onClick={() => setIsMenu(true)} />
          )}
        </Container>
      </LayoutContainer>

      <MoblilAside isMenu={isMenu} setIsMenu={setIsMenu} logout={logout} />
    </HeaderWraper>
  )
}
