import React from 'react'
import { Users as UsersController } from '../../../components/Admin/Users'

export const Users = () => {
  return (
    <>
      <UsersController />
    </>
  )
}
