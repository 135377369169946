import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Title
} from './styles'

export const TemplateItem = (props) => {
  const { item } = props

  return (
    <Container>
      <Link
        to={`/template/${item.name
          .toLowerCase()
          .replace(' ', '-')}`}
        state={item}
      >
        <img
          src={
            process.env.REACT_APP_BACKEND +
            '/' +
            item.mainPhoto
          }
          alt={item.name}
        />
        <Title>{item.name}</Title>
      </Link>
    </Container>
  )
}