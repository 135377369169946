import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { signout } from '../../../store/auth'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import { Input, TextArea } from '../../Shared/Inputs'
import { Button } from '../../Shared/Buttons'
import categoryService from '../../../service/categoryService'
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  FormWraper,
  FormRow,
  Label,
  ButtonWraper,
  ErrorContainer,
  ImageWrapper,
} from './styles'

export const Create = () => {
  const dispatch = useDispatch()
  const authStore = useSelector((store) => store.authStore)
  const settingStore = useSelector((store) => store.settingStore)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({})
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)

  const imageChange = (e) => {
    const file = e.target.files[0] || null
    setSelectedImage(file)
    setImage(URL.createObjectURL(file))
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const formData = new FormData()
    const entries = Object.entries(data)
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1])
    })
    formData.delete('image')
    formData.append('userId', authStore.auth.user._id)
    formData.append('image', selectedImage)

    const createCategory = async (data, token) => {
      const res = await categoryService.create(data, token)
      if (!res?.status && res?.error === 'token') dispatch(signout())
      if (!res?.status) {
        toast.error(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
    await createCategory(formData, authStore?.auth?.token)
    setLoading(false)
  }

  return (
    <LayoutContainer>
      <Container>
        <h1>{settingStore?.data?.setting?.cat_create_title}</h1>
        <p>
          {settingStore?.data?.setting?.cat_create_desc}
        </p>

        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Label>Name of Category</Label>
            <Input
              type="text"
              placeholder="Describe the image set, ex. 'Game of Thrones characters'"
              {...register('name', { required: 'Field is required' })}
            />
            <ErrorContainer>{errors?.name?.message}</ErrorContainer>
          </FormRow>

          <FormRow>
            <Label>Description of Category</Label>
            <TextArea
              placeholder="A great description helps users find your category in search results"
              {...register('description')}
            ></TextArea>
          </FormRow>

          <FormRow>
            <Label>Select Category Cover Photo</Label>
            <Controller
              name="image"
              control={control}
              render={({ field: { onChange } }) => (
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    onChange(e)
                    imageChange(e)
                  }}
                />
              )}
              rules={{ required: 'Field is required' }}
            />
            <ErrorContainer>
              {errors?.image?.message}
            </ErrorContainer>
            <ImageWrapper>
              {image && <img src={image} alt="main" />}
            </ImageWrapper>
          </FormRow>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={loading}
              isLoading={loading}
            >
              Create a Category
            </Button>
          </ButtonWraper>
        </FormWraper>
      </Container>

      <ToastContainer />
    </LayoutContainer>
  )
}
