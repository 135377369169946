import styled from 'styled-components'

export const Container = styled.div`
  margin: 50px 0px;
  @media (max-width: 576px) {
    margin: 30px 0px;
    h1 {
      font-size: 25px;
    }
  }
`
export const FormWraper = styled.form`
  margin: 0px auto;
  margin-top: 50px;
  max-width: 800px;
`

export const FormRow = styled.div`
  margin-bottom: 25px;
  p {
    margin: 5px 0px;
  }
`

export const Label = styled.div`
  font-size: 17px;
  font-weight: 600;
`

export const ButtonWraper = styled.div`
  display: flex;
  justify-content: center;
`

export const ErrorContainer = styled.div`
  color: red;
  font-size: 14px;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  img {
    margin-top: 5px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
`
