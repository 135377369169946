import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LayoutContainer } from '../Shared/LayoutContainer'
import { Input } from '../Shared/Inputs'
import { Button } from '../Shared/Buttons'
import { signin } from '../../store/auth'

import {
  Container,
  FormWraper,
  FormRow,
  FormLabel,
  FormInput,
  ErrorContainer,
  ButtonWraper,
} from './styles'

export const Login = () => {
  const [isSubmit, setIsSubmit] = useState(false)
  const dispatch = useDispatch()
  const authStore = useSelector((store) => store.authStore)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    if (!authStore?.auth?.status && isSubmit) {
      toast.error(authStore?.auth?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [authStore?.auth, isSubmit])

  const onSubmit = (data) => {
    setIsSubmit(false)
    data.role = 'user';
    dispatch(signin(data))
    setIsSubmit(true)
  }

  return (
    <Container>
      <LayoutContainer>
        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <h2>Login to Tier marker system.</h2>
          <FormRow>
            <FormLabel>Email Address :</FormLabel>
            <FormInput>
              <Input
                type="email"
                {...register('email', {
                  required: 'Email address is required',
                })}
              />
              <ErrorContainer>{errors?.email?.message}</ErrorContainer>
            </FormInput>
          </FormRow>

          <FormRow>
            <FormLabel>Password :</FormLabel>
            <FormInput>
              <Input
                type="password"
                {...register('password', {
                  required: 'Password is required',
                })}
              />
              <ErrorContainer>
                {errors?.password?.message}
              </ErrorContainer>
            </FormInput>
          </FormRow>
          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={authStore.loading}
              isLoading={authStore.loading}
            >
              Login
            </Button>
          </ButtonWraper>
        </FormWraper>
        <ToastContainer />
      </LayoutContainer>
    </Container>
  )
}
