import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  position: relative;
  width: 250px;
  background-color: #282828;
  color: white;
  height: 100vh;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s linear;
  overflow-y: auto;
  z-index: 10;

  ul{
    padding: 0px;
    li{
      list-style-type: none;
    }
  }
  >svg{
    position: absolute;
    color: white;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
  @media (max-width: 576px) {
    position: absolute;
    left: -250px;
    ${({ active }) => active && css`
      left: 0px;
    `}
  }
`

export const CustomLink = styled(Link)`
  display: block;
  width: 100%;
  color: white;
  padding: 20px;
  ${({ isselected }) => isselected === 'true' && css`
    background-color: #505050;
  `}
  &:hover{
    background-color: #505050;
  }
`

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 7px 15px;
  color: #ffbc00;
  border-radius: 10px;
  &:hover{
    cursor: pointer;
    background-color: #3c3c3c;
  }
  svg{
    margin-right: 15px;
    font-size: 20px;
  }
`