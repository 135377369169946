import styled, { css } from 'styled-components'

export const Container = styled.div`
  // margin: 80px 0px;
`

export const FormWraper = styled.form`
  text-align: center;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const FormLabel = styled.label`
  text-align: right;
  padding: 0px 10px;
  width: 30%;
  padding-top: 8px;

  @media (max-width: 576px) {
    text-align: left;
    width: 100%;
  }
`

export const FormInput = styled.div`
  width: 40%;
  text-align: left;

  @media (max-width: 576px) {
    width: 100%;
  }
`

export const ErrorContainer = styled.div`
  color: red;
  font-size: 14px;
`

export const ButtonWraper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`


export const CaptchaContainer = styled.div`
  border: 1px #d9d6d6 solid;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  max-width: 320px;
  input{
    font-size: 20px;
    border: none !important;
    width: 100px;
    padding: 7px 10px;
    outline: none;
  }
`

export const CaptchaContent = styled.div`
    width: 210px;
    height: 70px;
    font-size: 41px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 12px;
    color: #96b3e7;
    cursor: pointer;
    ${({ bgImage }) => css`
      background: url(${bgImage})
    `}
`