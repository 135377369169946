import React, { useState, useEffect } from 'react'
import { Input, TextArea } from '../../Shared/Inputs'
import { Button } from '../../Shared/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { update } from '../../../store/setting'

import {
  PageTitle,
  Section,
  FormWraper,
  FormRow,
  RowLabel,
  InputWraper,
  ButtonWraper,
  ErrorContainer
} from '../Shared/Common'

import {
  Container
} from './styles'

export const TemplateCreate = () => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false)
  const settingStore = useSelector((store) => store.settingStore)
  const adminStore = useSelector((store) => store.adminStore)
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({})

  useEffect(() => {
    setValue('temp_create_title', settingStore?.data?.setting?.temp_create_title);
    setValue('temp_create_desc', settingStore?.data?.setting?.temp_create_desc);
    if (isSubmit && !settingStore?.loading) {
      if (settingStore?.data?.status) {
        toast.success(settingStore?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(settingStore?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }, [settingStore, isSubmit])

  const onSubmit = (data) => {
    setIsSubmit(false)
    data._id = settingStore?.data?.setting?._id;
    data.token = adminStore?.admin?.token;
    dispatch(update(data))
    setIsSubmit(true)
  }

  return (
    <Container>
      <PageTitle>
        <h1>Template Create Page</h1>
      </PageTitle>
      <Section>
        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <RowLabel>Title: </RowLabel>
            <InputWraper>
              <Input
                type="text"
                {...register('temp_create_title', { required: 'Field is required' })}
              />
              <ErrorContainer>{errors?.temp_create_title?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <FormRow>
            <RowLabel>Description: </RowLabel>
            <InputWraper>
              <TextArea {...register('temp_create_desc', { required: 'Field is required' })}
              >
              </TextArea>
              <ErrorContainer>{errors?.temp_create_desc?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={settingStore?.loading}
              isLoading={settingStore?.loading}
            >
              Submit
            </Button>
          </ButtonWraper>
        </FormWraper>
      </Section>
      <ToastContainer />
    </Container >
  )
}
