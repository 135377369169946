import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { signout } from '../../../store/auth'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import { Input, SelectBox, TextArea } from '../../Shared/Inputs'
import { Button } from '../../Shared/Buttons'
import categoryService from '../../../service/categoryService'
import templateService from '../../../service/templateService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  FormWraper,
  FormRow,
  InputRow,
  Label,
  ButtonWraper,
  ErrorContainer,
  ImageWrapper,
} from './styles'

export const Create = () => {
  const dispatch = useDispatch()
  const authStore = useSelector((store) => store.authStore)
  const settingStore = useSelector((store) => store.settingStore)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({})
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [mainPhoto, setMainPhoto] = useState(null)
  const [selectedMainPhoto, setSelectedMainPhoto] = useState(null)
  const [images, setImages] = useState(null)
  const [selectedImages, setSelectedImages] = useState()

  const rows = ['S', 'A', 'B', 'C', 'D']
  const orientations = ['Squar', 'Landscape', 'Portrait', 'Circle']

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await categoryService.fetchAll()
      setResult(res)
    }
    fetchCategories()
  }, [])

  const mainPhotoChange = (e) => {
    const file = e.target.files[0]
    setSelectedMainPhoto(file)
    setMainPhoto(URL.createObjectURL(file))
  }

  const imagesChange = (e) => {
    const newImages = Object.values(e.target.files).map((item) =>
      URL.createObjectURL(item),
    )
    setSelectedImages(e.target.files)
    setImages(newImages)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const formData = new FormData()
    const entries = Object.entries(data)
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1])
    })
    formData.delete('mainPhoto')
    formData.delete('images')
    formData.append('userId', authStore.auth.user._id)
    formData.append('images', selectedMainPhoto)
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append(`images`, selectedImages[i])
    }

    const createTemplate = async (data, token) => {
      const res = await templateService.create(data, token)
      if (!res?.status && res?.error === 'token') dispatch(signout())
      if (!res?.status) {
        toast.error(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
    await createTemplate(formData, authStore?.auth?.token)
    setLoading(false)
  }

  return (
    <LayoutContainer>
      <Container>
        <h1>{settingStore?.data?.setting?.temp_create_title}</h1>
        <p>
          {settingStore?.data?.setting?.temp_create_desc}
        </p>

        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Label>Name of Template</Label>
            <Input
              type="text"
              placeholder="Describe the image set, ex. 'Game of Thrones characters'"
              {...register('name', { required: 'Field is required' })}
            />
            <ErrorContainer>{errors?.name?.message}</ErrorContainer>
          </FormRow>

          <FormRow>
            <Label>Select a Category</Label>
            {result?.status ? (
              <SelectBox
                {...register('categoryId', {
                  required: 'Field is required',
                })}
              >
                <option value="">Select a Category</option>
                {result.categories.map((item) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  )
                })}
              </SelectBox>
            ) : (
              <Skeleton height={40} />
            )}
            <ErrorContainer>
              {errors?.categoryId?.message}
            </ErrorContainer>
          </FormRow>

          <FormRow>
            <Label>Description of Template</Label>
            <TextArea
              placeholder="A great description helps users find your template in search results"
              {...register('description')}
            ></TextArea>
          </FormRow>

          <FormRow>
            <Label>Select Template Cover Photo</Label>
            <Controller
              name="mainPhoto"
              control={control}
              render={({ field: { onChange } }) => (
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    onChange(e)
                    mainPhotoChange(e)
                  }}
                />
              )}
              rules={{ required: 'Field is required' }}
            />
            <ErrorContainer>
              {errors?.mainPhoto?.message}
            </ErrorContainer>
            <ImageWrapper>
              {mainPhoto && <img src={mainPhoto} alt="main" />}
            </ImageWrapper>
          </FormRow>

          <FormRow>
            <Label>
              Upload a Set of Images for the Tier List Template
            </Label>
            <Controller
              name="images[]"
              control={control}
              render={({ field: { onChange } }) => (
                <Input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => {
                    onChange(e)
                    imagesChange(e)
                  }}
                />
              )}
              rules={{ required: 'Field is required' }}
            />
            <ErrorContainer>{errors?.images?.message}</ErrorContainer>
            <ImageWrapper>
              {images?.length > 0 &&
                images.map((item, ind) => (
                  <img src={item} key={ind} alt="images" />
                ))}
            </ImageWrapper>
          </FormRow>

          <FormRow>
            <Label>Add a URL for Image Credits</Label>
            <Input
              type="text"
              placeholder="URL of Site"
              {...register('siteUrl')}
            />
          </FormRow>

          <FormRow>
            <Label>Image Orientation</Label>
            <SelectBox {...register('orientation')}>
              {orientations.map((item, ind) => {
                return (
                  <option value={item} key={ind}>
                    {item}
                  </option>
                )
              })}
            </SelectBox>
          </FormRow>

          <FormRow>
            <Label>Default Row Label Text</Label>
            {rows.map((item, ind) => {
              return (
                <InputRow key={ind}>
                  <Input
                    {...register(`rows[${ind}]`)}
                    defaultValue={item}
                  />
                </InputRow>
              )
            })}
          </FormRow>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={!result?.status || loading}
              isLoading={loading}
            >
              Create a Template
            </Button>
          </ButtonWraper>
        </FormWraper>
      </Container>

      <ToastContainer />
    </LayoutContainer>
  )
}
