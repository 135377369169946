import axios from 'axios'

const create = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/tier/create',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      error: 'token',
      message: err.message,
    }
  }
}

const getByTempName = async (tempName) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/tier/get_by_temp_name',
      { tempName },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}
const tierService = {
  create,
  getByTempName,
}

export default tierService
