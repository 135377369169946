import styled from "styled-components";

export const Container = styled.div`
  margin: 5px;
  a {
    position: relative;
    img {
      width: 150px;
      height: 160px;
      object-fit: cover;
      @media (max-width: 576px) {
        width: 100px;
        height: 110px;
      }
    }
    &:hover {
      filter: brightness(1.2);
    }
  }
`
export const Title = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: black;
  color: white;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  @media (max-width: 576px) {
    padding: 2px;
    font-size: 10px;
  }
`
