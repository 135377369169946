import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-left: 15px;
`

export const DropDownContainer = styled.div`
  position: absolute;
  margin-top: 5px;
  right: 0px;
  box-shadow: 0 0 5px rgb(0 0 0 / 35%);
  background-color: white;
  ${({ isShow }) =>
    isShow ? { display: "block" } : { display: "none" }
  }

  a{
    color: #686868;
    white-space: nowrap;
    padding: 10px 20px;
    border-bottom: 1px solid #d3d3d3;
    margin: 0px;
    &:hover{
      background-color: #f7f7f7;
    }
  }
  border-radius: 5px;
`