import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { ContactHeader2 } from '../../components/Shared/HeaderTitle';
import { ErrorStyle, FormControl, InlineController, InputStyle, SubmitBtn, TextStyle } from '../Shared/ContactFormStyle';
import { postContact } from '../../service/contactService';

export const ContactForm = () => {

  const [isLoading, setIsloading] = useState(false)
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      subject: '',
      comment: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object({
        name: yup.string()
          .required('Name is required.'),
        email: yup.string()
          .email('Not valid email address')
          .required('Email is required.'),
        subject: yup.string()
          .required('Subject is required.'),
        comment: yup.string()
          .required('Comment is required.')
      }).required()
    )
  });

  const onSubmit = async (data) => {
    if (isLoading) return
    try {
      setIsloading(true)
      const res = await postContact(data)
      toast.success(res?.message)
      reset()
    } catch (error) {
      toast.error(error?.data?.error || 'Error has raised!')
    } finally {
      setIsloading(false)
    }

  }

  return (
    <ContactFormWrapper>
      <ContactHeader2>Inform Us</ContactHeader2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <InputStyle type='text' {...register("name")} invalid={!!errors.name} placeholder='Name' />
          <ErrorStyle>{errors.name?.message}</ErrorStyle>
        </FormControl>
        <InlineController>
          <FormControl>
            <InputStyle type='text' {...register("email")} invalid={!!errors.email} placeholder='Email' />
            <ErrorStyle>{errors.email?.message}</ErrorStyle>
          </FormControl>
          <FormControl>
            <InputStyle type='text' {...register("subject")} invalid={!!errors.subject} placeholder='Subject' />
            <ErrorStyle>{errors.subject?.message}</ErrorStyle>
          </FormControl>
        </InlineController>
        <FormControl>
          <TextStyle {...register("comment")} invalid={!!errors.comment} placeholder='Comment' ></TextStyle>
          <ErrorStyle>{errors.comment?.message}</ErrorStyle>
        </FormControl>
        <SubmitBtn isLoading={isLoading} type='submit'>Submit</SubmitBtn>
      </form>
    </ContactFormWrapper>
  )
}

export const ContactFormWrapper = styled.div`
  margin-bottom: 32px;
`