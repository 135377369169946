import axios from 'axios'

const fetchAll = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API + '/category/fetch_all',
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}

const fetchwithtemplate = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API + '/category/fetchwithtemplate',
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}

const create = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/category/create',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      error: 'token',
      message: err.message,
    }
  }
}

const categoryService = {
  fetchAll,
  fetchwithtemplate,
  create
}

export default categoryService
