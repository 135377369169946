import { Routes, Route } from "react-router-dom";
import { Profil } from './Profil'
import { PageNotFound } from '../../pages/PageNotFound'

export const AccountRouter = () => {
  return (
    <Routes>
      <Route path="/profil" element={<Profil />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}