import React, { useState, useEffect } from 'react'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import templateService from '../../../service/templateService'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { TemplateItem } from '../TemplateItem'

import { Container, TemplateContainer } from './styles'

export const ListByCategory = () => {
  const { catName } = useParams()
  const { width } = useWindowSize()
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const res = await templateService.getByCatName(catName)
      setData(res)
    }
    if (catName) fetchData()
  }, [catName])

  return (
    <LayoutContainer>
      <Container>
        {data?.status ? (
          <>
            <h1>{data?.category?.name} Tier List Templates</h1>
            <p>{data?.category?.description}</p>
            <TemplateContainer>
              {data?.templates?.length > 0
                ? data?.templates.map((item) =>
                  <TemplateItem item={item} key={item._id} />
                )
                : <h2>No data</h2>
              }
            </TemplateContainer>
          </>
        ) : (
          <>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <br />
            {[...Array(4).keys()].map((ind) => (
              <Skeleton height={width > 576 ? 160 : 110} key={ind} />
            ))}
          </>
        )}
      </Container>
    </LayoutContainer>
  )
}
