import styled, { css, keyframes } from "styled-components";

export const InputStyle = styled.input`
  width: 100%;
  border: none;
  background: #e8e7e9;
  padding: 15px 15px;
  transition: .3s;
  border-radius: 0;
  border: 2px solid #eeeeee17;
  color: black;
  font-size: 16px;

  &:focus {
    outline: none;
  }
  ${({ invalid }) => invalid && css`
    border-color: red;
  `}
`
export const ErrorStyle = styled.div`
  color: red;
  font-size: 14px;
  padding-top: 6px;
`
export const InlineController = styled.div`
  display: flex;
  flex-direction: column;
  @media(min-width: 768px) {
    flex-direction: row;
    column-gap: 24px;
    & > * {
      flex: 1;
    }
  }
`
export const FormControl = styled.div`
  margin-bottom: 24px;
`
export const TextStyle = styled.textarea`
  width: 100%;
  height: 100px;
  background: #e8e7e9;
  padding: 15px 15px;
  transition: .3s;
  border-radius: 0;
  border: 2px solid #eeeeee17;
  color: black;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  ${({ invalid }) => invalid && css`
    border-color: red;
  `}
`

const loadingSpineer = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
export const SubmitBtn = styled.button`
  position: relative;
  width: 210px;
  background-color: #5586df;
  border-radius: 9px;
  background-image: url(../img/bg/sbtn.png);
  font-size: 18px;
  color: #FFF;
  padding: 14px 30px;
  text-transform: uppercase;
  border: 1px solid #ffc010;
  cursor: pointer;
  ${({ isLoading }) => isLoading && css`
    color: transparent;
    &::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: white;
      border-bottom-color: white;
      border-radius: 50%;
      animation: ${loadingSpineer} 1s linear infinite;
    }
  `}
`