import React from 'react'
import ReactDOM from 'react-dom/client'
import { Router } from './router'
import theme from './theme.json'
import { ThemeProvider } from './contexts/ThemeContext'
import { Provider } from 'react-redux'
import store from './store'
import { fetchAll } from './store/setting'

import siteLogo from './assets/images/tiermaker-logo.png'
import adminLoginBg from './assets/images/admin_bg.jpg'
import captchaBg from './assets/images/captcha.png'

theme.images = {
  siteLogo,
  adminLoginBg,
  captchaBg
}

theme.icons = {}

store.dispatch(fetchAll())

const RouteApp = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<RouteApp />)
