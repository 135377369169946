import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { Input } from '../../../Shared/Inputs';
import { Button } from '../../../Shared/Buttons';
import { useSelector } from 'react-redux';
import authService from '../../../../service/authService';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  FormWraper,
  FormRow,
  FormLabel,
  FormInput,
  ErrorContainer,
  ButtonWraper
} from './styles'


export const InfoChange = () => {
  const authStore = useSelector((store) => store.authStore)
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: {
      name: authStore?.auth?.user?.name,
      email: authStore?.auth?.user?.email,
    },
  })

  const onSubmit = async (data) => {
    setLoading(true);
    data.id = authStore?.auth?.user?._id;
    const res = await authService.changeInfo(data, authStore?.auth?.token)
    if (!res?.status) {
      toast.error(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.success(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    setLoading(false);
  }

  return (
    <Container>
      <h3>Your Account Info</h3>
      <FormWraper onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormLabel>Name: </FormLabel>
          <FormInput>
            <Input
              type="text"
              {...register('name', {
                required: 'name is required',
              })}
            />
            <ErrorContainer>
              {errors?.name?.message}
            </ErrorContainer>
          </FormInput>
        </FormRow>
        <FormRow>
          <FormLabel>Email: </FormLabel>
          <FormInput>
            <Input
              type="email"
              {...register('email', {
                required: 'email is required',
              })}
            />
            <ErrorContainer>
              {errors?.email?.message}
            </ErrorContainer>
          </FormInput>
        </FormRow>
        <ButtonWraper>
          <Button
            color="primary"
            type="submit"
            disabled={loading}
            isLoading={loading}
          >
            Submit
          </Button>
        </ButtonWraper>
      </FormWraper>
      <ToastContainer />
    </Container>
  )
}