import styled from "styled-components";

export const Container = styled.div`
  width: calc(50% - 5px);
  margin-bottom: 10px;
  background-color: #303030;
  @media (max-width: 576px) {
    width: 100%;
  }
`

export const ImageWraper = styled.div`
  height: 220px;
  overflow: hidden;
  img{
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 576px) {
    height: 150px;
  }
`

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: white;
  background-color: black;
  svg{
    font-size: 25px;
    margin-right: 15px;
  }
`