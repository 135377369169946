import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authService from '../service/authService'

const auth = JSON.parse(localStorage.getItem('auth'))

export const signin = createAsyncThunk(
  'signin',
  async (data) => {
    return await authService.signin(data);
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: auth ? auth : null,
    loading: false,
  },

  reducers: {
    signout: (state) => {
      state.loading = false
      state.auth = null
      localStorage.removeItem('auth')
    },
  },

  extraReducers: (builder) => {
    // ============ login =================================
    builder.addCase(signin.pending, (state) => {
      state.loading = true
    })
    builder.addCase(signin.fulfilled, (state, action) => {
      state.loading = false
      state.auth = action.payload
    })
  },
})

export const { signout } = authSlice.actions

export default authSlice.reducer
