import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import authService from '../../../service/authService'
import { signout } from '../../../store/auth'
import { useDispatch } from 'react-redux'

export const Protected = (props) => {
  const dispatch = useDispatch();
  const authStore = useSelector((store) => store.authStore)

  useEffect(() => {
    const checkUser = async () => {
      const res = await authService.checkUser(authStore?.auth?.token);
      if (!res?.status) dispatch(signout())
    }
    checkUser();
  }, [])

  return (
    <>
      {!authStore?.auth?.token ? (
        <Navigate to="/login" />
      ) : (
        props.children
      )}
    </>
  )
}
