const Twitter = ({ width = 32, height = 27, fill = '#ffffff' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0515 26.0786C22.1268 26.0786 28.7325 16.0731 28.7325 7.41161C28.7325 7.13162 28.7325 6.84764 28.7205 6.56765C30.0065 5.63667 31.1165 4.48407 31.9983 3.16384C30.797 3.69351 29.5239 4.0426 28.2205 4.19978C29.5935 3.3791 30.6218 2.08783 31.1143 0.565974C29.8244 1.33018 28.4125 1.86665 26.9406 2.15189C25.951 1.09799 24.6415 0.399818 23.2149 0.16551C21.7884 -0.0687981 20.3244 0.173837 19.0497 0.855833C17.775 1.53783 16.7607 2.62112 16.164 3.93791C15.5674 5.25471 15.4215 6.73151 15.7492 8.13957C13.1388 8.00868 10.5851 7.33055 8.25365 6.14916C5.92222 4.96777 3.86516 3.30951 2.21588 1.28194C1.37861 2.72802 1.12309 4.43857 1.50121 6.06621C1.87934 7.69386 2.86276 9.11658 4.25177 10.0455C3.21091 10.0101 2.19292 9.7306 1.27993 9.22951V9.31951C1.28173 10.8344 1.80652 12.3022 2.7656 13.4748C3.72468 14.6474 5.05923 15.4529 6.54365 15.7552C5.98021 15.9104 5.39817 15.9878 4.81374 15.9852C4.40173 15.9864 3.99055 15.9482 3.58581 15.8712C4.00536 17.1752 4.82229 18.3154 5.92218 19.1319C7.02207 19.9485 8.34982 20.4006 9.71948 20.4249C7.39272 22.2524 4.51856 23.2436 1.55992 23.2388C1.03859 23.241 0.517619 23.2109 0 23.1488C3.00287 25.0632 6.49025 26.0797 10.0515 26.0786Z" fill={fill} />
    </svg>
  )
}

const LinkedIn = ({ width = 24, height = 24, fill = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
    </svg>
  )
}

const Discord = ({ width = 20.288, height = 13, fill = '#eeeeee' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20.288 13">
      <path fill={fill} d="M18.476,6.314a9.761,9.761,0,0,0-4.651-1.661.44.44,0,0,0-.438.259c0,.006-.158.456-.308.89a11.461,11.461,0,0,1,3.5,1.193.725.725,0,0,1-.729,1.253,10.4,10.4,0,0,0-4.707-1.17,10.418,10.418,0,0,0-4.709,1.17.725.725,0,0,1-.729-1.253A11.47,11.47,0,0,1,9.22,5.8c-.158-.439-.317-.882-.32-.888a.435.435,0,0,0-.438-.259,9.764,9.764,0,0,0-4.674,1.68C2.858,7.192,1,12.216,1,16.56a.435.435,0,0,0,.058.218c1.283,2.253,4.781,2.843,5.578,2.869H6.65a.442.442,0,0,0,.356-.181L7.869,18.3a8.769,8.769,0,0,1-2.951-1.217.725.725,0,0,1,.856-1.169,9.842,9.842,0,0,0,5.37,1.309,9.792,9.792,0,0,0,5.37-1.31.725.725,0,0,1,.857,1.169A8.726,8.726,0,0,1,14.43,18.3l.851,1.17a.44.44,0,0,0,.356.181h.014c.8-.025,4.3-.615,5.578-2.869a.435.435,0,0,0,.058-.218C21.288,12.216,19.43,7.192,18.476,6.314ZM8.246,15.048A1.661,1.661,0,0,1,6.8,13.236a1.661,1.661,0,0,1,1.449-1.811,1.661,1.661,0,0,1,1.449,1.811A1.661,1.661,0,0,1,8.246,15.048Zm5.8,0a1.661,1.661,0,0,1-1.449-1.811,1.661,1.661,0,0,1,1.449-1.811,1.661,1.661,0,0,1,1.449,1.811A1.661,1.661,0,0,1,14.042,15.048Z"></path>
    </svg>
  )
}

const OpenSeaIcon = ({ width = 20.288, height = 14, fill = '#eeeeee' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20.288 13">
      <path fill={fill} d="M16,7.5v0.9c0,0.1,0,0.1-0.1,0.1c-0.3,0.1-1.2,0.6-1.6,1.1c-1,1.4-1.8,3.4-3.5,3.4H3.6C1.1,13-1,10.9-1,8.4
							V8.3c0-0.1,0.1-0.1,0.1-0.1h4c0.1,0,0.1,0.1,0.1,0.2c0,0.3,0,0.5,0.1,0.8c0.2,0.5,0.7,0.8,1.3,0.8h2V8.3h-2c-0.1,0-0.2-0.1-0.1-0.2
							c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.4-0.7,0.7-1.1c0.2-0.3,0.4-0.7,0.5-1c0-0.1,0.1-0.1,0.1-0.2C6,5.6,6,5.5,6,5.4
							c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2-0.1-0.4C6.1,3,6,2.8,6,2.6l0-0.1
							c0-0.1-0.1-0.2-0.1-0.4C5.7,1.8,5.6,1.4,5.5,1C5.4,0.9,5.4,0.8,5.3,0.6C5.2,0.4,5.1,0.2,5.1,0.1C5,0,5-0.1,5-0.2
							c0-0.1-0.1-0.2-0.1-0.2S4.8-0.5,4.8-0.6L4.5-1c0-0.1,0-0.1,0.1-0.1l1.5,0.4l0,0l0,0l0.2,0.1l0.2,0.1l0.1,0v-0.9
							c0-0.4,0.3-0.8,0.8-0.8C7.6-2.3,7.8-2.2,8-2s0.2,0.3,0.2,0.6v1.3l0.2,0.1c0,0,0,0,0,0C8.4,0,8.5,0,8.6,0c0.1,0.1,0.1,0.1,0.2,0.2
							c0.1,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.1,0.1,0.1,0.1C9.6,1,9.9,1.3,10.2,1.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.3
							c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.7
							c0,0.1,0,0.1,0,0.2v0c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.3,0.6
							c0,0.1-0.1,0.1-0.1,0.2C11,6.6,11,6.7,10.9,6.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
							c-0.1,0.1-0.1,0.1-0.2,0.2C10,7.8,10,7.9,9.9,7.9C9.8,8,9.8,8.1,9.7,8.2L9.5,8.3c0,0-0.1,0-0.1,0H8.2v1.6h1.5
							c0.3,0,0.7-0.1,0.9-0.3c0.1-0.1,0.5-0.4,0.9-0.9c0,0,0,0,0.1,0l4.2-1.2C15.9,7.3,16,7.4,16,7.5z"></path>
    </svg>
  )
}

const ArtStationIcon = ({ width = 20.288, height = 14, fill = '#eeeeee' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 105.8 93.1">
      <path fill={fill} d="M0,71.8,8.9,87.2a10.741,10.741,0,0,0,9.5,5.9H77.7L65.5,71.8Zm105.8.1a10.643,10.643,0,0,0-1.7-5.8L69.3,5.7A10.593,10.593,0,0,0,59.9,0H41.5L95.2,93l8.5-14.7c1.6-2.8,2.1-4,2.1-6.4ZM56.7,56.6l-24-41.5L8.7,56.6Z" />
    </svg>
  )
}

const InstagramIcon = ({ width = 20.288, height = 14, fill = '#eeeeee' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20">
      <path fill={fill} d="M17.111 4.667c0 .666-.555 1.222-1.222 1.222a1.233 1.233 0 0 1-1.222-1.222c0-.667.555-1.223 1.222-1.223zM13.89 10c0-1.889-1.445-3.333-3.333-3.333-1.89 0-3.334 1.444-3.334 3.333 0 1.889 1.445 3.333 3.334 3.333zm1.778 0a5.097 5.097 0 0 1-5.111 5.111A5.097 5.097 0 0 1 5.444 10c0-2.889 2.223-5.111 5.112-5.111zm-1-9.889c1.11 0 1.777.222 2.444.445a4.35 4.35 0 0 1 1.778 1.11A4.35 4.35 0 0 1 20 3.445c.222.667.444 1.334.444 2.445.112 1 .112 1.444.112 4.111s-.112 3-.112 4.111c0 1.111-.222 1.778-.444 2.445a4.35 4.35 0 0 1-1.111 1.777 4.35 4.35 0 0 1-1.778 1.111c-.667.223-1.333.445-2.444.445-1 .111-1.445.111-4.111.111-2.667 0-3.112 0-4.112-.111-1.11 0-1.777-.222-2.444-.445a4.35 4.35 0 0 1-1.778-1.11 4.35 4.35 0 0 1-1.11-1.778c-.223-.667-.445-1.334-.445-2.445-.111-1-.111-1.444-.111-4.111s0-3.111.11-4.111c0-1.111.112-1.778.445-2.445a4.35 4.35 0 0 1 1.111-1.777A4.35 4.35 0 0 1 4 .556C4.667.333 5.333.11 6.444.11 7.444 0 7.89 0 10.556 0zM6.556 1.89c-1 0-1.556.222-1.89.333-.444.111-.777.334-1.11.778-.334.333-.556.667-.778 1.111C2.667 4.444 2.444 5 2.444 6c-.11 1-.11 1.333-.11 4s.11 3 .11 4 .223 1.556.334 1.889c.222.444.444.778.778 1.111.333.333.666.556 1.11.778.334.11.89.333 1.89.333 1 .111 1.333.111 4 .111 2.666 0 3-.11 4-.11s1.555-.223 1.888-.334c.445-.222.778-.445 1.112-.778.333-.333.555-.667.777-1.111.111-.333.334-.889.334-1.889.11-1 .11-1.333.11-4s-.11-3-.11-4-.223-1.556-.334-1.889c-.222-.444-.444-.778-.777-1.111-.334-.333-.667-.556-1.112-.778-.333-.11-.888-.333-1.888-.333-1-.111-1.334-.111-4-.111z"></path>
    </svg>
  )
}

const Facebook = ({ width = 20.288, height = 14, fill = '#eeeeee' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M15.9806 16.9537L16.8141 11.5246H11.6044V8.00146C11.6044 6.51646 12.3319 5.06803 14.6653 5.06803H17.0334V0.446152C17.0334 0.446152 14.8847 0.0795898 12.8297 0.0795898C8.53969 0.0795898 5.73563 2.68021 5.73563 7.3874V11.5255H0.966568V16.9546H5.73563V30.0796H11.6044V16.9546L15.9806 16.9537Z" />
    </svg>
  )
}

const ToTopIcon = ({ width = 17, height = 20, fill = '#eeeeee' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
      <path fill="#eee" d="M6.9 18.828a1.414 1.414 0 0 1-.018-.241V4.83l-.138.3a2.79 2.79 0 0 1-.55.788L2.387 9.776a1.41 1.41 0 0 1-1.824.202 1.408 1.408 0 0 1-.179-2.093L7.267.91a1.364 1.364 0 0 1 1.947 0h.001l6.883 6.976a1.409 1.409 0 0 1-.137 2.093 1.41 1.41 0 0 1-1.824-.202l-3.814-3.851a2.789 2.789 0 0 1-.502-.698l-.186-.418v13.7a1.44 1.44 0 0 1-1.156 1.473 1.379 1.379 0 0 1-1.58-1.154z"></path>
    </svg>
  )
}

const Close = ({ width = 48, height = 49, fill = '#000' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 49" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M27.5269 24.0098L47.4229 4.11382C47.8161 3.6547 48.0215 3.06411 47.9982 2.46009C47.9749 1.85607 47.7245 1.2831 47.2971 0.855669C46.8696 0.428242 46.2967 0.177843 45.6926 0.154512C45.0886 0.131182 44.498 0.336637 44.0389 0.729821L24.1429 20.6258L4.2469 0.70582C3.79498 0.253891 3.18203 0 2.5429 0C1.90378 0 1.29083 0.253891 0.838904 0.70582C0.386975 1.15775 0.133084 1.7707 0.133084 2.40982C0.133084 3.04894 0.386975 3.66189 0.838904 4.11382L20.7589 24.0098L0.838904 43.9058C0.587668 44.121 0.383619 44.3857 0.239564 44.6835C0.0955076 44.9813 0.0145552 45.3056 0.00178846 45.6361C-0.0109783 45.9666 0.0447148 46.2962 0.165374 46.6042C0.286033 46.9122 0.469052 47.1919 0.702944 47.4258C0.936835 47.6597 1.21655 47.8427 1.52453 47.9633C1.83251 48.084 2.16211 48.1397 2.49263 48.1269C2.82316 48.1142 3.14748 48.0332 3.44523 47.8892C3.74299 47.7451 4.00775 47.5411 4.2229 47.2898L24.1429 27.3938L44.0389 47.2898C44.498 47.683 45.0886 47.8885 45.6926 47.8651C46.2967 47.8418 46.8696 47.5914 47.2971 47.164C47.7245 46.7365 47.9749 46.1636 47.9982 45.5595C48.0215 44.9555 47.8161 44.3649 47.4229 43.9058L27.5269 24.0098Z" />
    </svg>
  )
}

const UploadIcon = ({ width = 24, height = 24, fill = '#000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M12 5c3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-13c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78zm0-2c-4.006 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408-.212-3.951-3.473-7.092-7.479-7.092zm4 10h-3v4h-2v-4h-3l4-4 4 4z" />
    </svg>
  )
}

const UploadFilledIcon = ({ width = 24, height = 24, fill = '#000' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
    </svg>
  )
}

const CheckOn = ({ width = 24, height = 24, fill = 'red' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M20 12.194v9.806h-20v-20h18.272l-1.951 2h-14.321v16h16v-5.768l2-2.038zm.904-10.027l-9.404 9.639-4.405-4.176-3.095 3.097 7.5 7.273 12.5-12.737-3.096-3.096z" />
    </svg>
  )
}

const CheckOff = ({ width = 24, height = 24, fill = '#ddd' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z" />
    </svg>
  )
}

const EditPageIcon = ({ width = 24, height = 24, fill = '#ddd' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm-3.002 10.125l1.832 1.832c.199.918-1.132 2.264-2.061 2.061l-1.832-1.832c-.084.284-.126.577-.126.871 0 .779.295 1.556.889 2.15.656.656 1.538.951 2.396.884.453-.034.9.131 1.221.452l3.034 3.034c.28.282.649.423 1.018.423.809 0 1.459-.663 1.442-1.472-.009-.358-.149-.714-.422-.988l-3.034-3.034c-.321-.322-.487-.769-.452-1.222.066-.858-.229-1.739-.884-2.395-.596-.595-1.373-.889-2.152-.889-.293 0-.586.042-.869.125zm6.841 8.417c0 .269-.219.486-.486.486-.269 0-.486-.218-.486-.486s.218-.485.486-.485.486.217.486.485z" />
    </svg>
  )
}

const ContactUsIcon = ({ width = 24, height = 24, fill = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M2.59 1.322l2.844-1.322 4.041 7.889-2.724 1.342c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-.598.273-1.215.399-1.842.397-5.649-.019-12.086-10.43-12.133-17.33-.016-2.407.745-4.387 2.59-5.348zm1.93 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.972-.474-2.271-4.383-1.026.501c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.498-2.252-4.398zm15.48 14.404h-1v-13h1v13zm-2-2h-1v-9h1v9zm4-1h-1v-7h1v7zm-6-1h-1v-5h1v5zm-2-1h-1v-3h1v3zm10 0h-1v-3h1v3zm-12-1h-1v-1h1v1z" />
    </svg>
  )
}

const MailIcon = ({ width = 24, height = 24, fill = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
    </svg>
  )
}

const LocationIcon = ({ width = 24, height = 24, fill = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
    </svg>
  )
}

const PhoneIcon = ({ width = 24, height = 24, fill = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M8.26 1.289l-1.564.772c-5.793 3.02 2.798 20.944 9.31 20.944.46 0 .904-.094 1.317-.284l1.542-.755-2.898-5.594-1.54.754c-.181.087-.384.134-.597.134-2.561 0-6.841-8.204-4.241-9.596l1.546-.763-2.875-5.612zm7.746 22.711c-5.68 0-12.221-11.114-12.221-17.832 0-2.419.833-4.146 2.457-4.992l2.382-1.176 3.857 7.347-2.437 1.201c-1.439.772 2.409 8.424 3.956 7.68l2.399-1.179 3.816 7.36s-2.36 1.162-2.476 1.215c-.547.251-1.129.376-1.733.376" />
    </svg>
  )
}

const DeleteIcon = ({ width = 24, height = 24, fill = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M5.22 2.53675H5.04C5.139 2.53675 5.22 2.45575 5.22 2.35675V2.53675H12.06V2.35675C12.06 2.45575 12.141 2.53675 12.24 2.53675H12.06V4.15675H13.68V2.35675C13.68 1.5625 13.0343 0.916748 12.24 0.916748H5.04C4.24575 0.916748 3.6 1.5625 3.6 2.35675V4.15675H5.22V2.53675ZM16.56 4.15675H0.72C0.32175 4.15675 0 4.4785 0 4.87675V5.59675C0 5.69575 0.081 5.77675 0.18 5.77675H1.539L2.09475 17.5442C2.13075 18.3115 2.76525 18.9167 3.5325 18.9167H13.7475C14.517 18.9167 15.1493 18.3137 15.1853 17.5442L15.741 5.77675H17.1C17.199 5.77675 17.28 5.69575 17.28 5.59675V4.87675C17.28 4.4785 16.9583 4.15675 16.56 4.15675ZM13.5743 17.2967H3.70575L3.16125 5.77675H14.1188L13.5743 17.2967Z" />
    </svg>
  )
}

const EditIcon = ({ width = 24, height = 24, fill = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9396 3.48294L13.6838 5.22637M13.0612 1.94704L8.34482 6.66344C8.10113 6.90679 7.93493 7.21684 7.86717 7.55451L7.43152 9.73524L9.61225 9.29876C9.9499 9.23123 10.2595 9.0657 10.5033 8.82193L15.2197 4.10553C15.3614 3.9638 15.4739 3.79555 15.5506 3.61037C15.6273 3.42519 15.6668 3.22672 15.6668 3.02628C15.6668 2.82585 15.6273 2.62738 15.5506 2.4422C15.4739 2.25702 15.3614 2.08877 15.2197 1.94704C15.078 1.80531 14.9097 1.69288 14.7246 1.61618C14.5394 1.53948 14.3409 1.5 14.1405 1.5C13.94 1.5 13.7416 1.53948 13.5564 1.61618C13.3712 1.69288 13.203 1.80531 13.0612 1.94704V1.94704Z" stroke="#0075FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0198 11.3823V13.8529C14.0198 14.2898 13.8463 14.7087 13.5374 15.0176C13.2285 15.3265 12.8096 15.5 12.3727 15.5H3.31382C2.87699 15.5 2.45805 15.3265 2.14917 15.0176C1.84028 14.7087 1.66675 14.2898 1.66675 13.8529V4.79401C1.66675 4.35718 1.84028 3.93824 2.14917 3.62935C2.45805 3.32047 2.87699 3.14693 3.31382 3.14693H5.78444" stroke="#0075FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

















const ArrowUpTriangle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 22h-24l12-20z" /></svg>
  )
}
const ArrowDwonTriangle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 21l-12-18h24z" /></svg>
  )
}
const GoBackIcon = () => {
  return (
    <svg clipRule="evenodd" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z" /></svg>
  )
}
const FingerPostRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.251 10c-5.876 0-6.689-9-9.804-9-1.862 0-2.444 1.667-2.444 3.613 0 1.125.195 2.343.45 3.387h-7.42c-1.788 0-3.033 1.421-3.033 3 0 1.59 1.258 3 3.033 3h3.434c.185.93.835 4.348 1.088 5.536.512 2.404 2.221 3.464 4.667 3.464 3.999 0 4.876-3 10.029-3h1.749v-10h-1.749zm-.251 8.002c-3.104.052-4.94 1.069-6.423 1.891-1.16.643-1.999 1.107-3.355 1.107-1.545 0-2.171-.521-2.475-1.167-.222-.472.122-1.017.646-1.017h.912c.316 0 .572-.256.572-.571 0-.316-.256-.572-.572-.572h-1.534c-.403 0-.75-.285-.828-.681-.104-.521.297-1.008.828-1.008h.83c.316 0 .572-.256.572-.571 0-.316-.256-.572-.572-.572h-1.273c-.405 0-.754-.287-.833-.685-.104-.524.299-1.014.833-1.014h.575c.316 0 .572-.256.572-.571 0-.316-.256-.572-.572-.572h-6.87c-.618.001-1.033-.459-1.033-.999 0-.46.359-1 1.033-1h9.967c-.176-.72-1.035-4.029-1.035-5.828 0-1 .482-1.527 1.107-.412.567 1.012 3.584 8.552 8.928 8.235v6.007z" /></svg>
  )
}

const TalkIcon = () => {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true"><g><path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path></g></svg>
  )
}
const CopyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.508 11.504l.93-2.494 2.998 6.268-6.31 2.779.894-2.478s-8.271-4.205-7.924-11.58c2.716 5.939 9.412 7.505 9.412 7.505zm7.492-9.504v-2h-21v21h2v-19h19zm-14.633 2c.441.757.958 1.422 1.521 2h14.112v16h-16v-8.548c-.713-.752-1.4-1.615-2-2.576v13.124h20v-20h-17.633z" /></svg>
  )
}

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember10027" ><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"></path></svg>
  )
}
const ArrowLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fill="none" viewBox="0 0 319 511.61"><path d="m270.08 5.89 43.04 43.04c7.85 7.86 7.83 20.72 0 28.54L134.77 255.82l178.35 178.35c7.85 7.86 7.8 20.73 0 28.54l-43.04 43.04c-7.83 7.82-20.71 7.82-28.54 0L49.29 313.49l-.37-.36-43.04-43.04c-7.82-7.83-7.86-20.68 0-28.54l43.04-43.04.37-.36L241.54 5.89c7.85-7.85 20.68-7.85 28.54 0z" /></svg>
  )
}

const ChevronLeft = () => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.50003 2.20564L2.28575 5.41992L5.50003 8.63421L4.85718 9.91992L0.357178 5.41992L4.85718 0.919922L5.50003 2.20564Z" fill="#757B82" />
    </svg>
  )
}
const ChevronRight = () => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 8.63421L3.71429 5.41992L0.5 2.20564L1.14286 0.919922L5.64286 5.41992L1.14286 9.91992L0.5 8.63421Z" fill="black" />
    </svg>
  )
}
const ArrowDown = () => {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0.5L7 5.5L12 0.5L14 1.5L7 8.5L0 1.5L2 0.5Z" fill="black" />
    </svg>
  )
}

const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember7977" ><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"></path>
    </svg>
  )
}

const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember7988" ><path d="M12 10.999c1.437.438 2.562 1.564 2.999 3.001.44-1.437 1.565-2.562 3.001-3-1.436-.439-2.561-1.563-3.001-3-.437 1.436-1.562 2.561-2.999 2.999zm8.001.001c.958.293 1.707 1.042 2 2.001.291-.959 1.042-1.709 1.999-2.001-.957-.292-1.707-1.042-2-2-.293.958-1.042 1.708-1.999 2zm-1-9c-.437 1.437-1.563 2.562-2.998 3.001 1.438.44 2.561 1.564 3.001 3.002.437-1.438 1.563-2.563 2.996-3.002-1.433-.437-2.559-1.564-2.999-3.001zm-7.001 22c-6.617 0-12-5.383-12-12s5.383-12 12-12c1.894 0 3.63.497 5.37 1.179-2.948.504-9.37 3.266-9.37 10.821 0 7.454 5.917 10.208 9.37 10.821-1.5.846-3.476 1.179-5.37 1.179z"></path></svg>
  )
}

const MobilePhoneIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.2808 24.328C30.1202 22.1337 26.0894 19.7543 25.9119 19.6504C25.3938 19.3556 24.8534 19.1995 24.3475 19.1995C23.5955 19.1995 22.9798 19.544 22.6071 20.1706C22.0174 20.8758 21.2862 21.7001 21.1087 21.8279C19.7352 22.7597 18.6599 22.6539 17.4703 21.4643L10.8307 14.8241C9.64854 13.6419 9.53992 12.5535 10.4654 11.1874C10.5949 11.0089 11.4192 10.277 12.1244 9.68679C12.5742 9.41915 12.8828 9.02144 13.0181 8.53355C13.1979 7.88439 13.0655 7.12077 12.6413 6.3768C12.5413 6.2057 10.1607 2.17427 7.96758 1.01409C7.5583 0.797318 7.09702 0.682861 6.63455 0.682861C5.87268 0.682861 5.15587 0.979985 4.61712 1.51816L3.14999 2.98472C0.829642 5.30448 -0.0102855 7.93409 0.652176 10.8001C1.2048 13.1887 2.81529 15.7304 5.4397 18.3542L13.9401 26.8548C17.2616 30.1763 20.4283 31.8607 23.3521 31.8607C23.3521 31.8607 23.3521 31.8607 23.3529 31.8607C25.5032 31.8607 27.5078 30.9469 29.3096 29.1449L30.7762 27.6785C31.6677 26.7876 31.87 25.4407 31.2808 24.328Z" fill="#0075FF" />
    </svg>
  )
}




const Youtube = () => {
  return (
    <svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.6254 3.78036C34.4259 3.06722 34.0457 2.41767 33.5215 1.8946C32.9973 1.37154 32.3469 0.992686 31.6333 0.794728C28.9548 0.0786475 18.1867 0.0786477 18.1867 0.0786477C18.1867 0.0786477 7.43906 0.0614701 4.73256 0.794728C4.02012 0.993556 3.37103 1.37281 2.84801 1.89582C2.325 2.41884 1.94575 3.06793 1.74692 3.78036C1.23826 6.52253 0.987731 9.30635 0.998632 12.0953C0.993669 14.8708 1.24415 17.6409 1.74692 20.3705C1.94639 21.0833 2.32575 21.7328 2.84859 22.2568C3.37142 22.7808 4.02017 23.1615 4.73256 23.3625C7.41115 24.0786 18.1867 24.0786 18.1867 24.0786C18.1867 24.0786 28.9279 24.0786 31.6333 23.3625C32.3469 23.1624 32.9969 22.782 33.5209 22.258C34.0449 21.734 34.4253 21.084 34.6254 20.3705C35.1182 17.6425 35.3587 14.8683 35.3415 12.0953C35.3587 9.30396 35.1236 6.52552 34.6254 3.78036ZM14.7469 17.2345V6.93348L23.7103 12.0953L14.7469 17.2345Z" fill="#0075FF" />
    </svg>
  )
}






const EllipseIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.9167" r="10" fill="#E3E3E3" />
    </svg>
  )
}




const SheveronUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember10241" className="feed-item__likes-icon ember-view"><path d="M0 15.878 l12-11.878 12 11.878-4 4.122-8-8-8 8-4-4.122z"></path>
    </svg>
  )
}

const CommentIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.97 122.88">
      <path d="M61.44,0a61.46,61.46,0,0,1,54.91,89l6.44,25.74a5.83,5.83,0,0,1-7.25,7L91.62,115A61.43,61.43,0,1,1,61.44,0ZM96.63,26.25a49.78,49.78,0,1,0-9,77.52A5.83,5.83,0,0,1,92.4,103L109,107.77l-4.5-18a5.86,5.86,0,0,1,.51-4.34,49.06,49.06,0,0,0,4.62-11.58,50,50,0,0,0-13-47.62Z" />
    </svg>
  )
}


const UploadImageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z" /></svg>
  )
}

const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember5049" className="nav-menu__icon ember-view">
      <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"></path>
    </svg>
  )
}

const CommunityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember5154" className="nav-dropdown__icon ember-view">
      <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z"></path>
    </svg>
  )
}

const StoreIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ember5168" className="nav-dropdown__icon ember-view">
      <path d="M11.6 24h-5.619l.019-10-2.983 8.721-3.017-.721 3.981-16 4-1.727c-.888-.944-1.355-2.337 0-3.242 0 0 1.432-1.018 4.019-1.027 2.587-.01 3.981.997 3.981.997 1.326.843.896 2.468 0 3.27l4 1.729 4.019 16-3 .73-3-8.73-.019 10h-5.581v-21.496c1.467-.05 2.6-.366 2.6-.748 0-.417-1.344-.755-3-.755s-3 .338-3 .755c0 .382 1.133.698 2.6.748v21.496zm3.545-7.021l-.645.469 2.355 3.234.645-.469-2.355-3.234zm-6.29 0l.645.469-2.355 3.234-.645-.469 2.355-3.234z">
      </path>
    </svg>
  )
}

const FileIcon = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M553.1 509.1l-77.8 99.2-41.1-52.4a8 8 0 0 0-12.6 0l-99.8 127.2a7.98 7.98 0 0 0 6.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 0 0-12.7 0zM360 442a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm494.6-153.4L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
    </svg>
  )
}

const VerifyIcon = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
    </svg>
  )
}

const UploadedFileIcon = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
    </svg>
  )
}

const DownloadFileIcon = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="blue" clipRule="evenodd"><path d="M23 0v20h-8v-2h6v-16h-18v16h6v2h-8v-20h22zm-12 13h-4l5-6 5 6h-4v11h-2v-11z"/></svg>
  )
}



export {
  Twitter,
  Discord,
  Facebook,
  Youtube,
  OpenSeaIcon,
  LinkedIn,
  ArtStationIcon,
  InstagramIcon,
  ToTopIcon,
  UploadIcon,
  UploadFilledIcon,
  CheckOn,
  CheckOff,
  EditPageIcon,
  ContactUsIcon,
  Close,
  MailIcon,
  PhoneIcon,
  LocationIcon,
  DeleteIcon,
  EditIcon,
 




  ArrowUpTriangle,
  ArrowDwonTriangle,
  GoBackIcon,
  FingerPostRight,
  MobilePhoneIcon,
  TalkIcon,
  CopyIcon,
  UploadImageIcon,

  ArrowRight,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  ArrowDown,

  
  UserIcon,
  LogoutIcon,
  EllipseIcon,
  SheveronUp,
  CommentIcon,
  MenuIcon,
  CommunityIcon,
  StoreIcon,
  FileIcon,
  VerifyIcon,
  UploadedFileIcon,
  DownloadFileIcon
}
