import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const LeftSide = styled.div`
  ${({ theme }) => theme.images.adminLoginBg && css`
    background-image: url(${theme.images.adminLoginBg});
  `}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  width: 60%;
  height: 100vh;
  @media (max-width: 968px) {
    display: none;
  }
`

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #26262e;
  color: white;
  flex: 1;
  height: 100vh;
`

export const FormWraper = styled.form`
  width: 90%;
  max-width: 400px;
  text-align: center;
  h2{
    margin-bottom: 50px;
  }
`

export const FormRow = styled.div`
  position: relative;
  margin-top: 30px;
  background-color: white;
  border-radius: 5px;
  svg{
    position: absolute;
    top: 10px;
    left: 10px;
    color: #7e7e7e;
    font-size: 20px;
  }
  input{
    padding-left: 40px;
  }
`

export const ButtonWraper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  button{
    width: 100%;
  }
`

export const ErrorContainer = styled.div`
  color: red;
  font-size: 12px;
`