import React from 'react';
import { useDispatch } from 'react-redux';
import { signout } from '../../../store/admin';
import { useLocation } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import { useWindowSize } from '../../../hooks/useWindowSize'
import { FiArrowLeft } from 'react-icons/fi'

import {
  Container,
  CustomLink,
  LogoutContainer
} from './styles'

const menuList = [
  { title: "User List", url: "/users" },
  { title: "Home Page", url: "/" },
  { title: "Template Create Page", url: "/temp_create" },
  { title: "Category Page", url: "/category" },
  { title: "Category Create Page", url: "/cat_create" },
  { title: "Contact List", url: "/contact_list" },
  { title: "Contact info", url: "/contact_info" }
]

export const Sidebar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const { width } = useWindowSize();
  const { isMobile, setIsMobile } = props

  const handleLogout = () => {
    dispatch(signout());
  }

  return (
    <Container active={isMobile}>
      <ul>
        {menuList.map((item, index) =>
          <li key={index}>
            <CustomLink
              to={`/admin${item.url}`}
              isselected={path === '/admin' + item.url ? 'true' : 'false'}
              onClick={() => setIsMobile(false)}
            >
              {item.title}
            </CustomLink>
          </li>
        )}
      </ul>
      <LogoutContainer onClick={handleLogout}>
        <BiLogOut /> Logout
      </LogoutContainer>
      {width < 576 &&
        <FiArrowLeft onClick={() => setIsMobile(false)} />
      }
    </Container>
  )
}