import styled, { css } from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #000000b3;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const Aside = styled.div`
  position: fixed;
  color: black;
  top: 0px;
  left: -220px;
  width: 220px;
  height: 100%;
  background-color: white;
  transition: all 0.3s linear;
  overflow-y: auto;
  z-index: 100;
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  ${({ active }) =>
    active &&
    css`
      left: 0px;
    `}
`

export const LinkContainer = styled.div`
  padding: 40px 10px;
  a {
    display: block;
    padding-left: 10px;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 10px;
    line-height: 2;
  }
`
