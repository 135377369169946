import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {
  Container,
  RowWraper,
  RowContent
} from './styles'

export const LoadingSkelleton = () => {

  return (
    <Container>
      <RowWraper>
        <Skeleton width={200} />
        <RowContent>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </RowContent>
      </RowWraper>
      <RowWraper>
        <Skeleton width={350} />
        <RowContent>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </RowContent>
      </RowWraper>
      <RowWraper>
        <Skeleton width={300} />
        <RowContent>
          <Skeleton />
          <Skeleton />
        </RowContent>
      </RowWraper>
    </Container>
  )
}
