import React from 'react'
import { Create } from '../../../components/Category/Create'

export const CreateCategory = () => {
  return (
    <>
      <Create />
    </>
  )
}
