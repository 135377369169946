import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 30px;
  h4 {
    margin: 10px 0px;
  }
`
export const TemplateContainer = styled.div`
  display: flex;
  overflow-x: auto;
  a {
    margin-right: 10px;
    position: relative;
    img {
      width: 140px;
      height: 140px;
      object-fit: cover;

      @media (max-width: 576px) {
        width: 100px;
        height: 100px;
      }
    }
    &:hover {
      filter: brightness(1.2);
    }
  }
`

export const Title = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: black;
  color: white;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  @media (max-width: 576px) {
    padding: 2px;
    font-size: 10px;
  }
`
