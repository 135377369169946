import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px 30px;
  @media (max-width: 576px) {
    padding: 0px 10px;
    h1 {
      margin-left: 40px;
    }
  }
`

export const ExportButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  svg {
    transform: rotate(180deg) scale(0.8);
  }
`
