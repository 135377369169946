import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 30px;
  @media (max-width: 576px) {
    padding: 0px 10px;
    h1{
      margin-left: 40px;
    }
  }
`

