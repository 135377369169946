import React from 'react'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import { InfoChange } from './InfoChange'
import { PasswordChange } from './PasswordChange'

import {
  Container,
  ContentWraper
} from './styles'

export const Profil = () => {
  return (
    <LayoutContainer>
      <Container>
        <h1>Your Profil Page</h1>
      </Container>
      <ContentWraper>
        <InfoChange />
        <PasswordChange />
      </ContentWraper>
    </LayoutContainer>
  )
}
