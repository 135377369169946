import axios from 'axios'

const signin = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/signin',
      data,
    )
    if (data.role === 'user') {
      localStorage.setItem('auth', JSON.stringify(response.data))
    } else {
      localStorage.setItem('admin', JSON.stringify(response.data))
    }
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const checkUser = async (token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/check_user',
      { token: token }
    )
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const checkAdmin = async (token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/check_admin',
      { token: token }
    )
    return response.data
  } catch (err) {
    console.log(err)
  }
}


const changeInfo = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/change_info',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}

const changePassword = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/change_password',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}

const getAllUsers = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_API + '/auth/get_all_users')
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}

const deleteUser = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/auth/delete_user',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      message: err.message,
    }
  }
}

const authService = {
  signin,
  checkAdmin,
  checkUser,
  changeInfo,
  changePassword,
  getAllUsers,
  deleteUser
}

export default authService
