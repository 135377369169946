import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import styled from 'styled-components'
import { Button } from '../../Shared/Buttons'
import { CheckOff, CheckOn, DeleteIcon } from '../../Shared/SvgIcons'
import {
  deleteContacts,
  getContacts,
  markAsReadContacts,
} from '../../../service/contactService'

export const ContactList = () => {
  const [allContacts, setAllContacts] = useState([])
  const [contacts, setContacts] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingRead, setIsLoadingRead] = useState(false)
  const [isViewAll, setIsViewAll] = useState(false)
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res = await getContacts()
        setAllContacts(res.contacts)
      } catch (error) {
        toast.error(error?.data?.error || 'Error has raised!')
      }
    }
    fetchContacts()
  }, [])
  useEffect(() => {
    if (allContacts) {
      isViewAll
        ? setContacts(allContacts)
        : setContacts(allContacts.filter((contact) => !contact.isRead))
    }
  }, [allContacts, isViewAll])
  useEffect(() => {
    selectedRows.length > 0 ? setIsDisabled(false) : setIsDisabled(true)
  }, [selectedRows])

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      maxWidth: '100px',
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      maxWidth: '180px',
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      sortable: true,
      maxWidth: '220px',
    },
    {
      name: 'Comment',
      selector: (row) => row.comment,
    },
    {
      name: 'Date',
      selector: (row) => moment(row.created).fromNow(),
      sortable: true,
      maxWidth: '180px',
    },
    {
      name: 'Read/Unread',
      selector: (row) =>
        row?.isRead ? (
          <CheckOn width={20} height={20} fill="green" />
        ) : (
          <CheckOff width={20} height={20} />
        ),
      sortable: true,
      maxWidth: '180px',
    },
  ]

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRows(selectedRows.map((row) => row._id))
  }

  const handleMarkAsRead = async () => {
    if (!selectedRows || !selectedRows.length) return
    if (isLoadingRead) return
    try {
      setIsLoadingRead(true)
      const res = await markAsReadContacts({ ids: selectedRows })
      toast.success(res?.message)
      setAllContacts(res?.contacts)
      setSelectedRows([])
    } catch (error) {
      toast.error(error?.data?.error || 'Error has raised!')
    } finally {
      setIsLoadingRead(false)
    }
  }

  const handleDelete = async () => {
    if (!selectedRows || !selectedRows.length) return
    if (isLoadingDelete) return
    try {
      setIsLoadingDelete(true)
      const res = await deleteContacts({ ids: selectedRows })
      toast.success(res?.message)
      setAllContacts(res?.contacts)
      setSelectedRows([])
    } catch (error) {
      toast.error(error?.data?.error || 'Error has raised!')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  return (
    <Container>
      <PageTitle>
        <h1>Contact List</h1>
      </PageTitle>
      <ActionWrapper>
        <Action>
          <Button
            disabled={isDisabled}
            color="primary"
            isLoading={isLoadingRead}
            onClick={handleMarkAsRead}
          >
            Mark as read &nbsp;{' '}
            <CheckOn width={20} height={20} fill={'#ffffff'} />{' '}
          </Button>
          <Button
            disabled={isDisabled}
            color="red"
            isLoading={isLoadingDelete}
            onClick={handleDelete}
          >
            Delete &nbsp; <DeleteIcon fill="red" />{' '}
          </Button>
        </Action>
        <Action>
          <Action>
            <input
              type={'radio'}
              name="isViewAll"
              value={isViewAll}
              checked={isViewAll}
              onChange={() => setIsViewAll(true)}
              id="view-all"
            />
            <label htmlFor="view-all">View All</label>
          </Action>
          <Action>
            <input
              type={'radio'}
              name="isViewAll"
              checked={!isViewAll}
              value={!isViewAll}
              onChange={() => setIsViewAll(false)}
              id="view-unread"
            />
            <label htmlFor="view-unread">View Only Unread</label>
          </Action>
        </Action>
      </ActionWrapper>
      {contacts && (
        <DataTable
          pagination
          selectableRows
          columns={columns}
          data={contacts}
          onSelectedRowsChange={handleSelectedRows}
        />
      )}
    </Container>
  )
}

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  input {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`
export const Container = styled.div`
  padding: 0px 30px;
  @media (max-width: 576px) {
    padding: 0px 10px;
    h1 {
      margin-left: 40px;
    }
  }
`
export const PageTitle = styled.div`
  h1 {
    @media (max-width: 576px) {
      font-size: 23px;
    }
  }
`
