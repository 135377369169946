import React from 'react'
import { Create } from '../../../components/Template/Create'

export const CreateTemplate = (props) => {
  return (
    <>
      <Create {...props} />
    </>
  )
}
