import React from 'react'
import { Register as RegisterController } from '../../components/Register'

export const Register = () => {
  return (
    <>
      <RegisterController />
    </>
  )
}
