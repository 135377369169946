import axios from 'axios'

export const postContact = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_API}/contact/`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response
    })
}

export const getContacts = async () => {
  return axios
    .get(`${process.env.REACT_APP_API}/contact/`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response
    })
}

export const getContactInfo = async () => {
  return axios
    .get(`${process.env.REACT_APP_API}/contact/info`)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response
    })
}

export const updateContactInfo = async (data) => {
  return axios
    .put(`${process.env.REACT_APP_API}/contact/info`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response
    })
}

export const deleteContacts = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_API}/contact/delete`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response
    })
}

export const markAsReadContacts = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_API}/contact/mark-as-read`, data)
    .then((data) => data.data)
    .catch((error) => {
      throw error.response
    })
}
