import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  ${({ hasFooter }) =>
    hasFooter &&
    css`
      min-height: calc(100vh - 50px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px;
      padding-top: 10px;
      @media (min-width: 576px) {
        min-height: calc(100vh - 65px);
      }
    `}
`

export const InnerContainer = styled.div`
  max-width: 100%;
  width: 1140px;
  margin: 0 auto;
`

export const Footer = styled.div`
  font-size: 13px;
  background: #212121;
  padding: 12px 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 12px;
  > div {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
  .contact-us {
    color: white;
    padding: 0px 2px;
    border-bottom: 1px solid white;
    :hover {
      color: green;
      border-color: green;
    }
  }
  @media (min-width: 576px) {
    padding: 12px 90px;
    flex-direction: row;
  }
`
