import styled from 'styled-components'

export const Container = styled.div`
  margin: 50px 0px;
  @media (max-width: 576px) {
    margin: 30px 0px;
    h1 {
      font-size: 25px;
    }
  }
`

export const ContentWraper = styled.div`
  /* margin-top: 30px; */
`
