import styled from "styled-components"
import { ContactHeader2 } from "../Shared/HeaderTitle"
import { LocationIcon, MailIcon, PhoneIcon } from "../Shared/SvgIcons"

export const ContactInfo = ({ contactInfo }) => {
  const { address, phone, email, desc } = contactInfo
  return (
    <ContactInfoWrapper>
      <ContactHeader2>Infomation</ContactHeader2>
      <div className="desc">{desc}</div>
      <ContactWrapper>
        <LocationIcon fill="darkgray"/>
        <div className="content">Address : {address}</div>
      </ContactWrapper>
      <ContactWrapper>
        <PhoneIcon fill="darkgray" />
        <div className="content">Phone : {phone}</div>
      </ContactWrapper>
      <ContactWrapper>
        <MailIcon fill="darkgray"/>
        <div className="content">Email : {email}</div>
      </ContactWrapper>
    </ContactInfoWrapper>
  )
}

export const ContactInfoWrapper = styled.div`
  .desc {
    margin-bottom: 36px;
    white-space: pre-wrap;
    color: gray;
  }  

`

export const ContactWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
  svg {
    display: inline-block;
    width: 24px;
  }
  .content {
    color: #90949c;
    display: block;
    font-size: 14px;
  }
  margin-bottom: 24px;
`