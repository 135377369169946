import styled from 'styled-components'
import contactHd from '../../assets/images/contact-hd.png'
import contactMap from '../../assets/images/contact-map.png'

export const ContactUsContainer = styled.div`
  position: relative;
`

export const ContactHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: url(${contactHd});
  height: 300px;
`
export const HeaderWrapper = styled.div`
  padding: 0 8px;
  h1 {
    text-align: center;
    font-size: 42px;
    margin-bottom: 25px;
    line-height: 1;
    color: #fff;
    margin-top: 80px;
  }
  p {
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    color: #dedede;
  }
  @media (min-width: 1024px) {
    margin-top: 0px;
    padding: 24px;
    h1 {
      font-size: 82px;
    }
    p {
      font-size: 22px;
    }
  }
`
export const ContactMap = styled.div`
  position: absolute;
  top: 350px;
  left: 0px;
  right: 0;
  height: calc(100vh - 500px);
  /* background: url(${contactMap}); */
`

export const Container = styled.div`
  margin-top: 130px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @media (min-width: 1024px) {
    flex-direction: row;
    column-gap: 24px;
    & > * {
      flex: 1;
    }
    max-width: 1140px;
    margin: 0 auto;
  }
`
