import React, { useEffect, useState } from 'react'
import { LayoutContainer } from '../Shared/LayoutContainer'
import categoryService from '../../service/categoryService'
import { CategoryRow } from './CategoryRow'
import { LoadingSkelleton } from './LoadingSkelleton'
import { useSelector } from 'react-redux'

import { Container, ContentWraper } from './styles'

export const Home = () => {
  const [categories, setCategories] = useState(null)
  const settingStore = useSelector((store) => store.settingStore)

  useEffect(() => {
    const fetchwithtemplate = async () => {
      const res = await categoryService.fetchwithtemplate()
      setCategories(res)
    }
    fetchwithtemplate()
  }, [])

  return (
    <LayoutContainer>
      <Container>
        <h1>{settingStore?.data?.setting?.home_title}</h1>
        <p>
          {settingStore?.data?.setting?.home_desc}
        </p>
        <ContentWraper>
          {categories?.status ? (
            categories?.result.map((item) => {
              return <CategoryRow category={item} key={item._id} />
            })
          ) : (
            <LoadingSkelleton />
          )}
        </ContentWraper>
      </Container>
    </LayoutContainer>
  )
}
