import { useEffect, useState } from 'react'
import { getContactInfo } from '../../service/contactService'
import {
  ContactHeader,
  ContactUsContainer,
  Container,
  HeaderWrapper,
} from './contact-us.styled'
import { ContactForm } from './ContactForm'
import { ContactInfo } from './ContactInfo'

export const ContactUs = () => {
  const [contactInfo, setContactInfo] = useState()
  useEffect(() => {
    async function fetchContactInfo() {
      try {
        const fetched = await getContactInfo()
        console.log(fetched)
        setContactInfo(fetched.contactInfo)
      } catch (error) {}
    }
    fetchContactInfo()
  }, [])

  return (
    <ContactUsContainer>
      <ContactHeader>
        <HeaderWrapper>
          <h1>Contact Us</h1>
          <p>Feel free to ask whatever you want to know..</p>
        </HeaderWrapper>
        <Container>
          {contactInfo && <ContactInfo contactInfo={contactInfo} />}
          <ContactForm />
        </Container>
      </ContactHeader>
    </ContactUsContainer>
  )
}
