import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai';
import { useSelector } from 'react-redux'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import categoryService from '../../../service/categoryService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Button } from '../../Shared/Buttons'
import { CategoryItem } from '../CategoryItem';

import { Container, ContentWraper, CategoryList, CreateBtnContainer } from './styles'

export const List = () => {
  const { width } = useWindowSize()
  const [data, setData] = useState(null)
  const authStore = useSelector((store) => store.authStore)
  const settingStore = useSelector((store) => store.settingStore)

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await categoryService.fetchAll()
      setData(res)
    }
    fetchCategories()
  }, [])

  return (
    <LayoutContainer>
      <Container>
        <h1>{settingStore?.data?.setting?.cat_title}</h1>
        <p>
          {settingStore?.data?.setting?.cat_desc}
        </p>
        <ContentWraper>
          {authStore?.auth?.token &&
            <CreateBtnContainer>
              <Link to='/category/create'>
                <Button color="primary">
                  <AiOutlinePlus /> Create New Category
                </Button>
              </Link>
            </CreateBtnContainer>
          }
          {data?.status ? (
            <CategoryList>
              {data?.categories?.length > 0
                ? data?.categories.map((item) =>
                  <CategoryItem item={item} key={item._id} />
                )
                : <h2>No Data</h2>
              }
            </CategoryList>
          ) : (
            <>
              {[...Array(4).keys()].map((ind) => (
                <Skeleton height={width > 576 ? 160 : 110} key={ind} />
              ))}
            </>
          )}
        </ContentWraper>
      </Container>
    </LayoutContainer>
  )
}
