import React from 'react'
import { ListByCategory as ListByCategoryController } from '../../../components/Template/ListByCategory'

export const ListByCategory = (props) => {
  return (
    <>
      <ListByCategoryController {...props} />
    </>
  )
}
