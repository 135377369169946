import React, { useState } from 'react'
import { FiSettings, FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { GrClose } from 'react-icons/gr'
import { Button } from '../../../Shared/Buttons'

import {
  Contiainer,
  ArrowContainer,
  ModalContainer,
  ModalOverlay,
  ModalContent,
  ButtonWraper,
} from './styles'

import {
  ColorPickerContainer,
  ColorPickers,
  ColorItem
} from '../styles'

export const Actions = (props) => {
  const {
    ind,
    moveUp,
    moveDown,
    addRowAbove,
    addRowBelow,
    deleteRow,
    clearRow,
    setTitleBg,
    selectedColor,
    colors
  } = props
  const [isModal, setIsModal] = useState(false)

  return (
    <Contiainer>
      <FiSettings onClick={() => setIsModal(true)} />
      <ArrowContainer>
        <FiChevronUp onClick={() => moveUp(ind)} />
        <FiChevronDown onClick={() => moveDown(ind)} />
      </ArrowContainer>
      {isModal && (
        <ModalContainer>
          <ModalOverlay />
          <ModalContent>
            <GrClose onClick={() => setIsModal(false)} />
            <ColorPickerContainer>
              <h3>Please select background color below!</h3>
              <ColorPickers>
                {colors.map(color =>
                  <ColorItem
                    color={color}
                    key={color}
                    selected={color === selectedColor}
                    onClick={() => {
                      setTitleBg(ind, color);
                      setIsModal(false)
                    }}
                  />
                )}
              </ColorPickers>
            </ColorPickerContainer>

            <ButtonWraper>
              <Button
                color="primary"
                onClick={() => {
                  addRowAbove(ind)
                  setIsModal(false)
                }}
              >
                Add a Row Above
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  addRowBelow(ind)
                  setIsModal(false)
                }}
              >
                Add a Row Below
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  clearRow(ind)
                  setIsModal(false)
                }}
              >
                Clear Row
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  deleteRow(ind)
                  setIsModal(false)
                }}
              >
                Delete Row
              </Button>
            </ButtonWraper>
          </ModalContent>
        </ModalContainer>
      )}
    </Contiainer>
  )
}
