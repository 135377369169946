import styled from 'styled-components'

export const Contiainer = styled.div`
  display: flex;
  background-color: #666666;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  border-bottom: 1px solid black;
  svg {
    margin: 5px;
    cursor: pointer;
    stroke-width: 3px;
    &:hover {
      color: #fdb5b5;
    }
    @media (max-width: 576px) {
      margin: 0px 3px;
    }
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0p;
`

export const ModalContent = styled.div`
  position: relative;
  max-width: 500px;
  width: 95%;
  margin: 0px auto;
  margin-top: 30px;
  border-radius: 5px;
  padding: 30px;
  background-color: white;
  color: black;
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

export const ButtonWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    margin: 5px;
    width: 200px;
    @media (max-width: 576px) {
      margin: 5px;
      width: 100%;
    }
  }
`
