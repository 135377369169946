import React from 'react'
import { CategoryCreate as CategoryCreateController } from '../../../components/Admin/CategoryCreate'

export const CategoryCreate = () => {
  return (
    <>
      <CategoryCreateController />
    </>
  )
}
