import styled from "styled-components";

export const Header2 = styled.h2`
  margin: 0 0 30px 0;
  color: #fff;
  font-family: JUNGLEFE;
  text-align: center;
  font-size: 35px;
  line-height: 35px;
  letter-spacing: 0.027em;
  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    margin: 30px auto 0 auto;
    background: #fff;
  }
  @media (min-width: 768px) {
    font-size: 45px;
    line-height: 45px;
    margin: 0 0 80px 0;
  }
`

export const ContactHeader2 = styled.h2`
  color: #5586df;
  font-size: 36px;
  display: inline-block;
  text-transform: uppercase;
  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    margin: 20px 0 0 0;
    background: #5586df;
  }
  margin-bottom: 30px;
`