import axios from 'axios'

const fetchAll = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_API + '/setting/fetch_all')
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const update = async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API + '/setting/update',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${data.token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const settingService = {
  fetchAll,
  update
}

export default settingService
