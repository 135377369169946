import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin: 50px 0px;
  img {
    height: 80px;
    width: auto;
    object-fit: cover;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 576px) {
      height: 50px;
    }
  }

  @media (max-width: 576px) {
    margin: 30px 0px;
    h1 {
      font-size: 25px;
    }
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
`

export const ImageItemWraper = styled.div`
  height: 80px;
  @media (max-width: 576px) {
    height: 50px;
  }
`

export const Rows = styled.div`
  display: flex;
`

export const RowImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid black;
  flex: 1;
  min-height: 81px;
  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `}
  @media (max-width: 576px) {
    min-height: 51px;
  }
`

export const Title = styled.div`
  width: 100px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
  font-size: 14px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `}
  @media (max-width: 576px) {
    width: 70px;
    font-size: 12px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  button {
    margin: 10px;
    width: 200px;
  }
`

export const ViewTemplateLink = styled.div`
  margin: 20px auto 0px auto;
  font-size: 20px;
  text-align: center;
  max-width: 700px;
  a{
    margin: 0px 10px;
    text-decoration: underline;
  }
`

export const ColorPickerContainer = styled.div`
  h3{
    text-align: center;
    margin-bottom: 2px;
  }
`

export const ColorPickers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 20px 10px;
  flex-wrap: wrap;
`

export const ColorItem = styled.span`
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  ${({ color }) => css`
    background-color: ${color};
  `}
  ${({ selected }) => selected && css`
    border: 3px solid red;
  `}
  cursor: pointer;
`