import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OutlineBtn } from '../../Shared/Buttons';

import {
  Container,
  DropDownContainer
} from './styles'

export const AccountDropDown = (props) => {
  const { logout } = props
  const [isShow, setIsShow] = useState(false)
  const ref = useRef();

  useEffect(() => {
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [])

  const closeMenu = (e) => {
    if (ref.current.contains(e.target))
      setIsShow(true);
    else
      setIsShow(false);
  }

  return (
    <Container>
      <OutlineBtn size="small" color="white" ref={ref}>
        Account
      </OutlineBtn>
      <DropDownContainer isShow={isShow}>
        <Link to="/account/profil">
          Profil
        </Link>
        {/* <Link to="/account/your_categories">
          Your Categories
        </Link>
        <Link to="/account/your_templates">
          Your Templates
        </Link>
        <Link to="/account/your_templates">
          Your Tiers
        </Link> */}
        <Link to="/" onClick={logout}>
          LogOut
        </Link>

      </DropDownContainer>
    </Container>
  )
}