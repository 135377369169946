import React, { useState, useEffect } from 'react'
import authService from '../../../service/authService'
import DataTable from 'react-data-table-component';
import { Button } from '../../Shared/Buttons'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DownloadFileIcon } from '../../Shared/SvgIcons';
import {
  PageTitle,
  Section,
  ModalContainer,
  ModalOverlay,
  ModalContent,
  ButtonWraper
} from '../Shared/Common'

import {
  Container, ExportButtonWrapper,
} from './styles'
import exportService from '../../../service/exportService';

export const Users = () => {
  const [users, setUsers] = useState([])
  const [isModal, setIsModal] = useState(false)
  const [userId, setUserId] = useState(null)
  const adminStore = useSelector((store) => store.adminStore)

  useEffect(() => {
    const getData = async () => {
      const res = await authService.getAllUsers();
      if (res.status) setUsers(res.users);
    }
    getData();
  }, [])

  const onDelete = (id) => {
    setIsModal(true);
    setUserId(id);
  }

  const userDelete = async () => {
    const res = await authService.deleteUser({ id: userId }, adminStore?.admin?.token);
    if (res.status) {
      toast.success(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      const newArr = users.filter(item => item._id !== userId);
      setUsers(newArr);
    } else {
      toast.error(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    setIsModal(false);
  }

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => new Date(row.createdAt).toJSON().slice(0, 10),
      sortable: true,
    },
    {
      name: 'Delete',
      cell: row => (
        <Button size="small" color="danger" onClick={() => onDelete(row._id)}> Delete </Button>
      )
    },
  ];

  return (
    <Container>
      <PageTitle>
        <h1>User List</h1>
      </PageTitle>
      <Section>
        <ExportButtonWrapper>
          <a href={`${process.env.REACT_APP_API}/export/users`}><DownloadFileIcon /> Export</a>
        </ExportButtonWrapper>
        <DataTable
          columns={columns}
          data={users}
          pagination
          selectableRows
        />
      </Section>
      {isModal &&
        <ModalContainer>
          <ModalOverlay />
          <ModalContent>
            <h2>Are you sure?</h2>
            <ButtonWraper>
              <Button color="danger" onClick={() => userDelete()}> Yes </Button>
              <Button color="secondary" onClick={() => setIsModal(false)}> No </Button>
            </ButtonWraper>
          </ModalContent>
        </ModalContainer>
      }
      <ToastContainer />
    </Container >
  )
}
