import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authService from '../service/authService'

const admin = JSON.parse(localStorage.getItem('admin'))

export const signin = createAsyncThunk(
  'signin',
  async (data) => {
    return await authService.signin(data);
  },
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    admin: admin ? admin : null,
    loading: false,
  },

  reducers: {
    signout: (state) => {
      state.loading = false
      state.admin = null
      localStorage.removeItem('admin')
    },
  },

  extraReducers: (builder) => {
    // ============ login =================================
    builder.addCase(signin.pending, (state) => {
      state.loading = true
    })
    builder.addCase(signin.fulfilled, (state, action) => {
      state.loading = false
      state.admin = action.payload
    })
  },
})

export const { signout } = adminSlice.actions

export default adminSlice.reducer
