import styled, { css } from 'styled-components'
import { darken } from 'polished'

export const Input = styled.input`
  background: ${(props) =>
    props.theme.colors?.backgroundPage || '#FFF'};
  color: ${(props) => props.theme.colors.headingColor};
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 15px;
  outline: none;
  width: 100%;

  &:focus {
    border-color: ${() => darken(0.07, '#CCC')};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: #b1bccc;
  }
  &:-ms-input-placeholder {
    color: #b1bccc;
  }
  &[type='file'] {
    &:hover {
      cursor: pointer;
    }
  }
`

export const TextArea = styled.textarea`
  background: ${(props) =>
    props.theme.colors?.backgroundPage || '#FFF'};
  color: ${(props) => props.theme.colors.headingColor};
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 15px;
  outline: none;
  width: 100%;
  max-width: 100%;

  &:focus {
    border-color: ${() => darken(0.07, '#CCC')};
  }
  ${({ w }) =>
    w &&
    css`
      width: 20%;
    `}
  &::placeholder,
  &::-webkit-input-placeholder {
    color: #b1bccc;
  }
  &:-ms-input-placeholder {
    color: #b1bccc;
  }
  &:read-only {
    background-color: #f8f9fa;
    cursor: not-allowed;
    border-color: #dee2e6;
  }
`

export const SelectBox = styled.select`
  background: ${(props) =>
    props.theme.colors?.backgroundPage || '#FFF'};
  color: ${(props) => props.theme.colors.headingColor};
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 15px;
  outline: none;
  width: 100%;
  &:focus {
    border-color: ${() => darken(0.07, '#CCC')};
  }
`
