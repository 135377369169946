import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import html2canvas from 'html2canvas'
import downloadjs from 'downloadjs'
import tierService from '../../../service/tierService'
import templateService from '../../../service/templateService'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import { Actions } from './Actions'
import { Button } from '../../Shared/Buttons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useParams } from 'react-router-dom'

import {
  Container,
  Rows,
  RowImageContainer,
  ImageItemWraper,
  Title,
  ImageContainer,
  ButtonContainer,
  ViewTemplateLink,
  ColorPickerContainer,
  ColorPickers,
  ColorItem
} from './styles'

export const ViewTemplate = () => {
  const { tempName } = useParams();
  const authStore = useSelector((store) => store.authStore)
  const [template, setTemplate] = useState(null)
  const [loading, setLoading] = useState(false)
  const ref = useRef();
  const [data, setData] = useState(null)
  const [bgColor, setBgColor] = useState('#3B3B3B')
  const colors = ['#FF7F7F', '#FFBF7F', '#FFDF7F', '#FFFF7F', '#BFFF7F', '#7FFF7F', '#7FFFFF', '#7FBFFF', '#7F7FFF', '#FF7FFF', '#BF7FBF', '#3B3B3B', '#858585', '#CFCFCF', '#F7F7F7', '#000000']

  // ---------- Initialiazation for the images ------------------
  useEffect(() => {
    const getTemplate = async () => {
      const res = await templateService.getByName(tempName)
      if (res?.status) {
        const newData = res.template?.rows.map((v) => {
          return {
            title: v,
            photo: [],
          }
        })
        newData.push({ title: 'images', photo: res.template?.images })
        setData(newData)
        setTemplate(res?.template)
      }
    }
    getTemplate();
  }, [])
  // -----------------------------------------------------------------

  // ------------ Image Drag and Drop ----------------------------------
  const move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
  ) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)
    destClone.splice(droppableDestination.index, 0, removed)
    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    return result
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    const sInd = source.droppableId
    const dInd = destination.droppableId

    if (sInd === dInd) {
      const items = reorder(
        data[sInd].photo,
        source.index,
        destination.index,
      )
      const newData = [...data]
      newData[sInd].photo = items
      setData(newData)
    } else {
      const result = move(
        data[sInd].photo,
        data[dInd].photo,
        source,
        destination,
      )
      const newData = [...data]
      newData[sInd].photo = result[sInd]
      newData[dInd].photo = result[dInd]
      setData(newData)
    }
  }
  // -----------------------------------------------------------------

  console.log(data);
  // ------------ Setting Actions(moveUp, moveDown, clearRow, addRow, removeRow...) ----------------------------------
  const setTitleBg = (ind, color) => {
    const newData = [...data]
    newData[ind].color = color
    setData(newData)
  }

  const moveUp = (ind) => {
    if (ind > 0) {
      const newData = [...data]
      const temp = newData[ind]
      newData[ind] = newData[ind - 1]
      newData[ind - 1] = temp
      setData(newData)
    }
  }

  const moveDown = (ind) => {
    if (ind < data.length - 2) {
      const newData = [...data]
      const temp = newData[ind]
      newData[ind] = newData[ind + 1]
      newData[ind + 1] = temp
      setData(newData)
    }
  }

  const addRowAbove = (ind) => {
    const newData = [...data]
    newData.splice(ind, 0, { title: 'New', photo: [] })
    setData(newData)
  }

  const addRowBelow = (ind) => {
    const newData = [...data]
    newData.splice(ind + 1, 0, { title: 'New', photo: [] })
    setData(newData)
  }

  const deleteRow = (ind) => {
    const newData = [...data]
    newData[data.length - 1].photo.push(...newData[ind].photo)
    newData.splice(ind, 1)
    setData(newData)
  }

  const clearRow = (ind) => {
    const newData = [...data]
    newData[data.length - 1].photo.push(...newData[ind].photo)
    newData[ind].photo = []
    setData(newData)
  }

  const changeTitle = (e, ind) => {
    const newData = [...data]
    newData[ind].title = e.target.innerText.trim()
    setData(newData)
  }
  // -----------------------------------------------------------------

  // ----------- Download Image --------------------------------------
  const downloadImage = async () => {
    const element = ref.current
    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
    })
    const imageUrl = canvas.toDataURL('image/png', 1.0)
    downloadjs(imageUrl, 'download.png', 'image/png')
  }
  // ---------------------------------------------------------------

  // ----------- Save Tier --------------------------------------
  const saveTier = async () => {
    setLoading(true)
    const element = ref.current
    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
    })
    const imageUrl = canvas.toDataURL('image/png', 0.5)

    const tierData = {
      userId: authStore?.auth?.user._id,
      templateId: template?._id,
      bgColor: bgColor,
      imageUrl: imageUrl,
      rows: data,
    }

    const res = await tierService.create(tierData, authStore?.auth?.token)
    if (!res?.status) {
      toast.error(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.success(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    setLoading(false)
  }
  // ---------------------------------------------------------------

  return (
    <LayoutContainer>
      <Container>
        <h1>{template?.name} Tier List Maker</h1>
        <p>{template?.description}</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <div ref={ref}>
            {data?.map(
              (el, ind) =>
                el.title !== 'images' && (
                  <Droppable
                    droppableId={`${ind}`}
                    direction="horizontal"
                    key={ind}
                  >
                    {(provided) => (
                      <Rows>
                        <Title
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                          onBlur={(e) => changeTitle(e, ind)}
                          bgColor={el?.color || colors[ind % 15]}
                        >
                          {el.title}
                        </Title>
                        <RowImageContainer
                          ref={provided.innerRef}
                          bgColor={bgColor}
                          {...provided.droppableProps}
                        >
                          {el.photo?.map((item, index) => (
                            <Draggable
                              draggableId={`${ind}-${index}`}
                              index={index}
                              key={index}
                            >
                              {(provided) => (
                                <ImageItemWraper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_BACKEND +
                                      '/' +
                                      item
                                    }
                                    alt="drag and drop"
                                  />
                                </ImageItemWraper>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </RowImageContainer>
                        <Actions
                          ind={ind}
                          moveUp={moveUp}
                          moveDown={moveDown}
                          addRowAbove={addRowAbove}
                          addRowBelow={addRowBelow}
                          deleteRow={deleteRow}
                          clearRow={clearRow}
                          setTitleBg={setTitleBg}
                          selectedColor={el?.color || colors[ind % 15]}
                          colors={colors}
                        />
                      </Rows>
                    )}
                  </Droppable>
                ),
            )}
          </div>

          {data?.map(
            (el, ind) =>
              el.title === 'images' && (
                <Droppable
                  droppableId={`${ind}`}
                  direction="horizontal"
                  key={ind}
                >
                  {(provided) => (
                    <ImageContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {data.find((item) => item.title === 'images')
                        ?.photo?.map((item, index) => (
                          <Draggable
                            draggableId={`${ind}-${index}`}
                            index={index}
                            key={index}
                          >
                            {(provided) => (
                              <ImageItemWraper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND +
                                    '/' +
                                    item
                                  }
                                  alt="drag and drop"
                                />
                              </ImageItemWraper>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </ImageContainer>
                  )}
                </Droppable>
              ),
          )}
        </DragDropContext>

        <ButtonContainer>
          <Button color="primary" onClick={downloadImage}>
            Donwload Tier Image
          </Button>
          {authStore.auth?.token && (
            <Button
              color="primary"
              onClick={saveTier}
              disabled={loading}
              isLoading={loading}
            >
              Save Tier
            </Button>
          )}
        </ButtonContainer>
        <ColorPickerContainer>
          <h3>Please select background color below!</h3>
          <ColorPickers>
            {colors.map(color =>
              <ColorItem color={color} key={color} selected={color === bgColor} onClick={() => setBgColor(color)} />
            )}
          </ColorPickers>
        </ColorPickerContainer>
        <ViewTemplateLink>
          View the Community Ranking for this
          <Link to={`/tier/${template?.name.toLowerCase().replace(' ', '-')}`}>
            {template?.name}
          </Link>
          Tier List & recent user lists
        </ViewTemplateLink>
      </Container>

      <ToastContainer />
    </LayoutContainer >
  )
}
