import React from 'react'
import { TemplateCreate as TemplateCreateController } from '../../../components/Admin/TemplateCreate'

export const TemplateCreate = () => {
  return (
    <>
      <TemplateCreateController />
    </>
  )
}
