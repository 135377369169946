import React from 'react'
import { Login as LoginController } from '../../../components/Admin/Login'

export const Login = () => {
  return (
    <>
      <LoginController />
    </>
  )
}
