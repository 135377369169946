import React from 'react'
import { Home as HomeController } from '../../../components/Admin/Home'

export const Home = () => {
  return (
    <>
      <HomeController />
    </>
  )
}
