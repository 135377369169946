import React, { useState, useEffect } from 'react'
import { Input, TextArea } from '../../Shared/Inputs'
import { Button } from '../../Shared/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { update } from '../../../store/setting'

import {
  PageTitle,
  Section,
  FormWraper,
  FormRow,
  RowLabel,
  InputWraper,
  ButtonWraper,
  ErrorContainer
} from '../Shared/Common'

import {
  Container,
  LogoWraper
} from './styles'

export const Home = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false)
  const settingStore = useSelector((store) => store.settingStore)
  const adminStore = useSelector((store) => store.adminStore)
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({})
  const [logoUrl, setLogoUrl] = useState(settingStore?.data?.setting?.logo || theme.images.siteLogo)

  useEffect(() => {
    setValue('home_title', settingStore?.data?.setting?.home_title);
    setValue('home_desc', settingStore?.data?.setting?.home_desc);
    if (isSubmit && !settingStore?.loading) {
      if (settingStore?.data?.status) {
        toast.success(settingStore?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(settingStore?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }, [settingStore, isSubmit])

  const handleChange = (e) => {
    const file = e.target.files[0] || null
    let reader = new FileReader();
    reader.onloadend = function () {
      setLogoUrl(reader.result)
    }
    reader.readAsDataURL(file);
  }

  const onSubmit = (data) => {
    setIsSubmit(false)
    data.logo = logoUrl;
    data._id = settingStore?.data?.setting?._id;
    data.token = adminStore?.admin?.token;
    dispatch(update(data))
    setIsSubmit(true)
  }

  return (
    <Container>
      <PageTitle>
        <h1>Home Page</h1>
      </PageTitle>
      <Section>
        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <RowLabel>Logo: </RowLabel>
            <InputWraper>
              <LogoWraper htmlFor="file">
                <Controller
                  name="logo"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Input
                      type="file"
                      accept="image/*"
                      id="file"
                      onChange={(e) => {
                        onChange(e)
                        handleChange(e)
                      }}
                    />
                  )}
                />
                <img src={logoUrl} alt="Logo" />
              </LogoWraper>
            </InputWraper>
          </FormRow>

          <FormRow>
            <RowLabel>Title: </RowLabel>
            <InputWraper>
              <Input
                type="text"
                {...register('home_title', { required: 'Field is required' })}
              />
              <ErrorContainer>{errors?.home_title?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <FormRow>
            <RowLabel>Description: </RowLabel>
            <InputWraper>
              <TextArea {...register('home_desc', { required: 'Field is required' })}
              >
              </TextArea>
              <ErrorContainer>{errors?.home_desc?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={settingStore?.loading}
              isLoading={settingStore?.loading}
            >
              Submit
            </Button>
          </ButtonWraper>
        </FormWraper>
      </Section>
      <ToastContainer />
    </Container >
  )
}
