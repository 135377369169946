import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 30px;
  @media (max-width: 576px) {
    padding: 0px 10px;
    h1{
      margin-left: 40px;
    }
  }
  input[type='file']{
    display: none;
  }
`

export const LogoWraper = styled.label`
  background-color: #464646;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  img{
    height: 40px;
  }
`