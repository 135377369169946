import styled, { css, keyframes } from 'styled-components'

const loadingSpineer = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  padding: 8px 14px;
  font-size: 16px;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in;

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 3px 10px;
    `}

  ${({ color }) =>
    color === 'primary' &&
    css`
      background-color: #5586df;
      color: white;
    `}
  ${({ color }) =>
    color === 'warning' &&
    css`
      background-color: #fbb462;
      color: white;
    `}
  ${({ color }) =>
    color === 'danger' &&
    css`
      background-color: #cd5353;
      color: white;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: inherit;
      &:hover {
      }
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ color }) =>
    color === 'primary' &&
    css`
      &:hover {
        background-color: #305597;
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      color: transparent;
      ::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid transparent;
        border-top-color: #ffffff;
        border-bottom-color: #ffffff;
        border-radius: 50%;
        animation: ${loadingSpineer} 1s linear infinite;
      }
    `}
`

export const OutlineBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 15px;
  font-family: inherit;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in;
  background-color: transparent;

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 3px 10px;
    `}

  ${({ color }) => css`
    border: 1px solid ${color};
    color: ${color};
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: inherit;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`
