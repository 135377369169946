import React from 'react'
import { Link } from 'react-router-dom'

import { Container, Footer, InnerContainer } from './styles'

export const LayoutContainer = ({ children, hasFooter = true }) => {
  return (
    <Container hasFooter={hasFooter}>
      <InnerContainer>{children}</InnerContainer>
      {hasFooter && (
        <Footer>
          <div>
            <img src="footer-logo.png" width={20} height={20} />
            <span>
              <strong>&#169;</strong> 2022, by Carnivores
            </span>
          </div>
          <Link className='contact-us' to={'/contact_us'}>Contact Us</Link>
        </Footer>
      )}
    </Container>
  )
}
