import React from 'react'
import { ViewTemplate as ViewTemplateContainer } from '../../../components/Template/ViewTemplate'

export const ViewTemplate = (props) => {
  return (
    <>
      <ViewTemplateContainer {...props} />
    </>
  )
}
