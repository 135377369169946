import React from 'react'
import { useSelector } from 'react-redux'

import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Header } from './components/Header'

// pages
import { Protected } from './components/Shared/Protected'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { Register } from './pages/Register'
import { PageNotFound } from './pages/PageNotFound'
import { CreateTemplate } from './pages/Template/Create'
import { CategoryList } from './pages/Category/List'
import { CreateCategory } from './pages/Category/Create'
import { ListByCategory } from './pages/Template/ListByCategory'
import { ViewTemplate } from './pages/Template/ViewTemplate'
import { ListByTempName } from './components/Tier/ListByTempName'
import { AccountRouter } from './pages/Account'
import { AdminRouter } from './pages/Admin'
import { ContactUs } from './pages/ContactUs'

export const App = () => {
  const location = useLocation();
  const path = location.pathname;
  const authStore = useSelector((store) => store.authStore)

  return (
    <>
      {!path.includes('/admin') && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={authStore?.auth?.token ? <Navigate to="/" /> : <Login />} />
        <Route path="/register" element={authStore?.auth?.token ? <Navigate to="/" /> : <Register />} />
        <Route path="/categories" element={<CategoryList />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/category/:catName" element={<ListByCategory />} />
        <Route path="/template/:tempName" element={<ViewTemplate />} />
        <Route path="/tier/:tempName" element={<ListByTempName />} />
        <Route path="/template/create" element={<Protected><CreateTemplate /></Protected>} />
        <Route path="/category/create" element={<Protected><CreateCategory /></Protected>} />

        <Route path="/account/*" element={<Protected><AccountRouter /></Protected>} />

        <Route path="/admin/*" element={<AdminRouter />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  )
}
