import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
`

export const RowWraper = styled.div`
  > div{
    margin-bottom: 30px;
  }
  > span span{
    height: 30px;
  }
`

export const RowContent = styled.div`
  display: flex;
  > span span{
    height: 140px;
    width: 140px;
    margin-right: 10px;
    @media (max-width: 576px) {
      height: 100px;
      width: 100px;
    }
  }
`