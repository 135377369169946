import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Input } from '../../Shared/Inputs'
import { Button } from '../../Shared/Buttons'
import { RxLockClosed } from 'react-icons/rx';
import { AiOutlineMail } from 'react-icons/ai';
import { signin } from '../../../store/admin'

import {
  Container,
  LeftSide,
  RightSide,
  FormWraper,
  FormRow,
  ButtonWraper,
  ErrorContainer
} from './styles';

export const Login = () => {
  const [isSubmit, setIsSubmit] = useState(false)
  const dispatch = useDispatch()
  const adminStore = useSelector((store) => store.adminStore)
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    if (!adminStore?.admin?.status && isSubmit) {
      toast.error(adminStore?.admin?.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [adminStore?.admin, isSubmit])

  const onSubmit = (data) => {
    setIsSubmit(false)
    data.role = 'admin';
    dispatch(signin(data))
    setIsSubmit(true)
  }

  return (
    <Container>
      <LeftSide />
      <RightSide>
        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <h2>Welcome to Admin!</h2>
          <FormRow>
            <Input
              type="email"
              {...register('email', {
                required: 'email is required',
              })}
              placeholder="Your Email"
            />
            <AiOutlineMail />
          </FormRow>
          <ErrorContainer>
            {errors?.email?.message}
          </ErrorContainer>

          <FormRow>
            <Input
              type="password"
              {...register('password', {
                required: 'Password is required',
              })}
              placeholder="Password"
            />
            <RxLockClosed />
          </FormRow>
          <ErrorContainer>
            {errors?.password?.message}
          </ErrorContainer>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={adminStore.loading}
              isLoading={adminStore.loading}
            >
              Login
            </Button>
          </ButtonWraper>

        </FormWraper>
      </RightSide>
      <ToastContainer />
    </Container>
  )
}