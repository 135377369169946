import React, { useEffect, useState } from 'react'
import { LayoutContainer } from '../../Shared/LayoutContainer'
import tierService from '../../../service/tierService'
import { useParams } from 'react-router-dom'
import { TierItem } from '../TierItem'

import {
  Container,
  TiersContainer
} from './styles'

export const ListByTempName = () => {
  const { tempName } = useParams();
  const [data, setData] = useState(null)

  useEffect(() => {
    const getData = async () => {
      const res = await tierService.getByTempName(tempName)
      setData(res)
    }
    getData();
  }, [])

  return (
    <LayoutContainer>
      <Container>
        <h1>{data?.result?.name} Tier List Community Rankings</h1>
        <p>{data?.result?.description}</p>
        {data?.status
          ? <TiersContainer>
            {data?.result?.tiers?.length > 0
              ? data?.result?.tiers?.map(item =>
                <TierItem item={item} key={item._id} />
              )
              : <h2>No Data</h2>
            }
          </TiersContainer>
          : 'loadding...'
        }
      </Container>
    </LayoutContainer>
  )
}
