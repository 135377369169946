import styled from "styled-components";

export const PageTitle = styled.div`
  h1{
    @media (max-width: 576px) {
      font-size: 23px;
    }
  }
`

export const Section = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 4px 5px 7px #e9e9e9;
  @media (max-width: 576px) {
    padding: 20px 15px;
  }
`

export const FormWraper = styled.form`
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const RowLabel = styled.label`
  width: 15%;
`

export const InputWraper = styled.div`
  flex: 1;
`

export const ButtonWraper = styled.div`
  display: flex;
  justify-content: center;
  button{
    margin: 0px 10px;
  }
`

export const ErrorContainer = styled.div`
  color: red;
  font-size: 12px;
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0p;
`

export const ModalContent = styled.div`
  position: relative;
  max-width: 300px;
  width: 95%;
  margin: 0px auto;
  margin-top: 30px;
  border-radius: 5px;
  padding: 10px 30px 20px 30px;
  background-color: white;
  color: black;
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`
