import axios from 'axios'

const create = async (data, token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/template/create',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      error: 'token',
      message: err.message,
    }
  }
}

const getByCatName = async (catName) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/template/get_templates_by_cat_name',
      { catName },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      error: 'token',
      message: err.message,
    }
  }
}

const getByName = async (tempName) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + '/template/get_template_by_name',
      { tempName },
    )
    return response.data
  } catch (err) {
    return {
      status: false,
      error: 'token',
      message: err.message,
    }
  }
}


const templateService = {
  create,
  getByCatName,
  getByName
}

export default templateService
