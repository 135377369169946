import React from 'react'
import { Link } from 'react-router-dom'

import { Container, TemplateContainer, Title } from './styles'

export const CategoryRow = ({ category }) => {
  return (
    <>
      {category?.templates?.length > 0 &&
        <Container>
          <h4>
            Featured{' '}
            <Link
              to={`/category/${category.name.toLowerCase().replace(' ', '-')}`}
            >
              {category.name}
            </Link>{' '}
            Templates
          </h4>
          <TemplateContainer>
            {category.templates.map((item) => (
              <Link
                to={`/template/${item.name.toLowerCase().replace(' ', '-')}`}
                key={item._id}
              >
                <img
                  src={process.env.REACT_APP_BACKEND + '/' + item.mainPhoto}
                  alt={item.name}
                />
                <Title>{item.name}</Title>
              </Link>
            ))}
          </TemplateContainer>
        </Container>
      }
    </>
  )
}
