import React, { useState, useEffect, useCallback } from 'react'
import { Input, TextArea } from '../../Shared/Inputs'
import { Button } from '../../Shared/Buttons'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  PageTitle,
  Section,
  FormWraper,
  FormRow,
  RowLabel,
  InputWraper,
  ButtonWraper,
  ErrorContainer,
} from '../Shared/Common'

import { Container } from './styles'
import {
  getContactInfo,
  updateContactInfo,
} from '../../../service/contactService'

export const ContactInfo = () => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const resetAsyncForm = useCallback(async () => {
    const { contactInfo } = await getContactInfo()
    reset(contactInfo)
  }, [reset])

  useEffect(() => {
    resetAsyncForm()
  }, [resetAsyncForm])

  const onSubmit = async (data) => {
    if (loading) return
    try {
      setLoading(true)
      const updated = await updateContactInfo({ contactInfo: data })
      toast.success('Updated successfully!')
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <PageTitle>
        <h1>ContactInfo Page</h1>
      </PageTitle>
      <Section>
        <FormWraper onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <RowLabel>Address: </RowLabel>
            <InputWraper>
              <Input
                type="text"
                {...register('address', {
                  required: 'Address is required',
                })}
              />
              <ErrorContainer>
                {errors?.address?.message}
              </ErrorContainer>
            </InputWraper>
          </FormRow>

          <FormRow>
            <RowLabel>Phone: </RowLabel>
            <InputWraper>
              <Input
                type="text"
                {...register('phone', {
                  required: 'Phone is required',
                })}
              />
              <ErrorContainer>{errors?.phone?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <FormRow>
            <RowLabel>Email: </RowLabel>
            <InputWraper>
              <Input
                type="text"
                {...register('email', {
                  required: 'Email is required',
                })}
              />
              <ErrorContainer>{errors?.email?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <FormRow>
            <RowLabel>Description: </RowLabel>
            <InputWraper>
              <TextArea
                {...register('desc', {
                  required: 'Description is required',
                })}
              ></TextArea>
              <ErrorContainer>{errors?.desc?.message}</ErrorContainer>
            </InputWraper>
          </FormRow>

          <ButtonWraper>
            <Button
              color="primary"
              type="submit"
              disabled={loading}
              isLoading={loading}
            >
              Submit
            </Button>
          </ButtonWraper>
        </FormWraper>
      </Section>
      <ToastContainer />
    </Container>
  )
}
