import styled from "styled-components";

export const Container = styled.div`
  width: calc(50% - 5px);
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 0px 10px 15px 10px;
  margin-bottom: 10px;
  color:#4e4e4e;
  @media (max-width: 576px) {
    width: 100%;
  }
`

export const FormWraper = styled.form`
`

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

export const FormLabel = styled.label`
  width: 32%;
  padding: 6px;
  white-space: nowrap;
  @media (max-width: 576px) {
    width: 100%;
  }
`

export const FormInput = styled.div`
  flex: 1;
`

export const ErrorContainer = styled.div`
  color: red;
  font-size: 13px;
`

export const ButtonWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`